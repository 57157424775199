import axios from 'axios'
import { getToken } from '@/utils/auth'
import { Toast } from 'vant'

let isTest = false
if (location.href.indexOf('ecs3') != -1 || location.href.indexOf('192.168.1.') != -1 || location.href.indexOf('localhost') != -1) {
  isTest = true
}
console.log(isTest, 'isTest')
console.log(process.env.VUE_APP_BASE_API_JAVA_TEST)
// create an axios instance
const service = axios.create({
  baseURL: isTest ? process.env.VUE_APP_BASE_API_JAVA_TEST : process.env.VUE_APP_BASE_API_JAVA,
  // baseURL: 'https://cloud-dev.gpets.cn',
  // baseURL: 'https://cloud.gpets.cn',
  timeout: 15000
})

// request interceptor
service.interceptors.request.use(

  config => {
    console.log(getToken(), 'getToken()getToken()getToken()')
    config.headers['device'] = 'ios'
    if (getToken() && getToken() != 'undefined') {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    } else {
      config.headers['Authorization'] = 'Basic Y2xpZW50OjEyMzQ1Ng=='
    }
    // if (config.url.indexOf('api/buried_point') != -1) {
    //   config.headers['pointAppKey'] = 'Dmq2nnDTnpyZBNEhy6IC'
    //   config.headers['device'] = 'H5'
    //   config.headers['v'] = '2.14.1'
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // console.log(res)
    // console.log(response.config)
    if (res.code != '1' && !response.config.hideToast) {
      Toast({
        message: res.msg,
        duration: 2000
      })
    }
    return res
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
