import request from '@/utils/request'
import requestJava from '@/utils/requestJava'
export function scanCount(data) {
  return request({
    url: `api/server/scan/count/`,
    method: 'get',
    params: data
  })
}

export function getDownLoadUrl(data) {
  return request({
    url: `api/tools/system/config/`,
    method: 'get',
    params: data
  })
}
// 获取协议
export function getAgreement(type) {
  return requestJava({
    url: `/user-server/app-api/agreement/v1/getAgreement/${type}`,
    method: 'get'
  })
}
