<template>
  <div class="topic-list">
    <div class="bg-img-div">
      <img
        :src="detail_info.backgroundUrl"
        class="bg-img"
        alt=""
      >
    </div>
    <div class="scroll-div">
      <div class="top-div pl-32 pr-32 pt-38 ">
        <div class="topic-name">#{{ detail_info.name }}</div>
        <div class="num mt-16 mb-16"><span class="mr-16">{{ detail_info.joinedQuantity }}条讨论</span>
          <span>{{ detail_info.viewedQuantity }}人浏览</span></div>
        <div class="desc limit-text2">{{ detail_info.intro }}</div>
      </div>
      <div
        class="list-div"
        :class="[showDiv?'showDiv':'']"
      >
        <div class="tabs">
          <div
            class="tab"
            :class="[selected?'selected':'']"
            @click="chooseType(1)"
          >最新</div>
          <div
            class="tab"
            :class="[!selected?'selected':'']"
            @click="chooseType(2)"
          >最热</div>
        </div>
        <div
          class="list-out"
          :style="{height:listOutHeight+20+'px'}"
        >
          <div
            v-if="showWater"
            class="show-water"
          >
            <div
              v-for="(m,i) in moments"
              :key="i"
              ref="waterfallItem"
              class="topic-list-left topic-list-water"
              @click="detailEvent(m)"
            >
              <div>
                <div
                  v-if="m.mediaList&&m.mediaList.length&&m.mediaList[0]"
                  class="content-div"
                >
                  <img
                    v-if="m.mediaList[0].mediaType=='2'"
                    class="img"
                    :src="m.mediaList[0].mediaUrl+'?x-oss-process=video/snapshot,h_350,t_0,f_jpg'"
                    alt=""
                  >
                  <img
                    v-else
                    class="img"
                    :src="m.mediaList[0].mediaUrl"
                    alt=""
                  >
                  <img
                    v-if="m.mediaList[0].mediaType=='1'"
                    class="play"
                    src="../../../assets/video/play.png"
                    alt=""
                  >
                  <div class="text-div">
                    <div class="desc limit-text">{{ m.content }}</div>
                    <div
                      v-if="m.topicList&&m.topicList.length"
                      class="tags flex"
                    >#{{ m.topicList[0].name }}</div>
                    <div class="user">
                      <div class="flex flex-v-c">
                        <img
                          class="avatar mr-8"
                          :src="m.user.avatar||'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nTkuEN5PhFQOjnRCHox5.jpg'"
                          alt=""
                        >
                        <span class="name">{{ m.user.nickname }}</span>
                      </div>
                      <img
                        src="../../../assets/video/attention_gray.png"
                        alt=""
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="topic-list-left">-->
          <!--<div v-for="(m,i) in moments" :key="i">-->
          <!--<div v-if="i%2==0" class="content-div">-->
          <!--<van-image v-if="m.video.length>0" class="img" fix="contain"    :src="m.video+'?x-oss-process=video/snapshot,w_350,t_0,f_jpg'" alt=""/>-->
          <!--<van-image v-else class="img" :src="$filters.goodsThumb(m)" fix="contain"   alt=""/>-->
          <!--<img v-if="m.video.length>0" class="play" src="../../../assets/video/play.png" alt="">-->
          <!--<div class="text-div">-->
          <!--<div class="desc limit-text">{{ m.content }}</div>-->
          <!--<div class="tags flex">#{{ data_info.name }}</div>-->
          <!--<div class="user">-->
          <!--<div class="flex flex-v-c">-->
          <!--<img class="avatar mr-8" :src="m.user.avatar||'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nTkuEN5PhFQOjnRCHox5.jpg'" alt="">-->
          <!--<span class="name">{{ m.user.nickname }}</span>-->
          <!--</div>-->
          <!--<img src="../../../assets/video/attention_gray.png" alt="">-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--<div class="topic-list-right">-->
          <!--<div v-for="(m,i) in moments" :key="i+'j'">-->
          <!--<div v-if="i%2!=0" class="content-div">-->
          <!--<van-image v-if="m.video.length>0" class="img" fix="contain"    :src="m.video+'?x-oss-process=video/snapshot,w_350,t_0,f_jpg'" alt=""/>-->
          <!--<van-image v-else class="img" :src="$filters.goodsThumb(m)" fix="contain"   alt=""/>-->
          <!--<img v-if="m.video.length>0" class="play" src="../../../assets/video/play.png" alt="">-->
          <!--<div class="text-div">-->
          <!--<div class="desc limit-text">{{ m.content }}</div>-->
          <!--<div class="tags flex">#{{ data_info.name }}</div>-->
          <!--<div class="user">-->
          <!--<div class="flex flex-v-c">-->
          <!--<img class="avatar mr-8" :src="m.user.avatar||'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nTkuEN5PhFQOjnRCHox5.jpg'" alt="">-->
          <!--<span class="name">{{ m.user.nickname }}</span>-->
          <!--</div>-->
          <!--<img src="../../../assets/video/attention_gray.png" alt="">-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
          <!--</div>-->
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { defineComponent, ref, reactive, onMounted, nextTick, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'ShareTopic',
  props: ['info', 'infoTwo', 'detailInfo'],
  setup(props) {
    const data_info = reactive(props.info)
    const detail_info = reactive(props.detailInfo)

    const selected = ref(false)
    const showWater = ref(true)
    const moments = ref(data_info)
    setTimeout(() => {
      console.log(moments, 'moments 7777777')
    }, 2000)
    const listOutHeight = ref(0)

    const { proxy } = getCurrentInstance()
    const array = ref([])
    const router = useRouter()
    const showDiv = ref(false)
    const chooseType = (type) => {
      moments.value = []
      showWater.value = false
      if (type == '1') {
        moments.value = props.info
        selected.value = true
      } else {
        selected.value = false
        moments.value = props.infoTwo
      }
      array.value = []
      showWater.value = true
      setTimeout(() => {
        nextTick(() => {
          // setTimeout(()=>{
          getWaterfall()
          // },300)
        })
      }, 200)
    }

    const getWaterfall = () => {
      const columns = 2 // 定义布局的列数为2
      const item = proxy.$refs.waterfallItem // 获取每个子元素的DOM

      for (let i = 0; i < item.length; i++) {
        // 遍历整个子元素的DOM集合
        if (i < columns) {
          // 小于columns的子元素作为第一行
          item[i].style.top = 20 + 'px'
          item[i].style.left = item[0].clientWidth * i + 'px'
          array.value.push(item[i].clientHeight) // 遍历结束时，数组array.value保存的是第一行子元素的元素高度
        } else {
          // 大于等于columns的子元素将作其他行
          const minHeight = Math.min(...array.value) //  找到第一列的最小高度
          const index = array.value.findIndex(item => item === minHeight) // 找到最小高度的索引
          // 设置当前子元素项的位置
          item[i].style.top = array.value[index] + 25 + 'px'
          item[i].style.left = item[index].offsetLeft + 'px'
          // 重新定义数组最小项的高度 进行累加
          array.value[index] += item[i].clientHeight
          listOutHeight.value = array.value[index]
        }
      }
      setTimeout(() => {
        showDiv.value = true
      }, 10)
    }
    const detailEvent = (data) => {
      // if (data.video.length > 0) {
      //   router.push({ path: '/share', query: { video_play: data.id }})
      // } else {
      //   router.push({ path: '/share', query: { moment: data.id }})
      // }
      // setTimeout(() => {
      //   // location.reload()
      // }, 500)
    }
    onMounted(() => {
      setTimeout(() => {
        getWaterfall()
        // updateWaterfall()
      }, 500)
    })
    return {
      data_info,
      chooseType,
      showWater,
      moments,
      listOutHeight,
      detailEvent,
      selected,
      showDiv,
      detail_info
    }
  }
})
</script>

<style scoped lang="scss">

   .topic-list{

       position: relative;
       width: 100%;
       div,span{
          box-sizing: border-box;
       }
     .bg-img-div{
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 350px;
       .bg-img{
         position: relative;

         width: 100%;
         height: 350px;
         object-fit:cover;
       }

     }

       .top-div{
         background: rgba(0, 0, 0, 0.25);
           position: relative;
           z-index: 8;
         padding-bottom: 52px;
           div,span{
               color: #ffffff;
           }
           .top-name{

               color: #FFFFFF ;
               font-size: 40px;
               font-weight: 500;
           }
           .num{
               color: #B2B2B2;
               font-size: 24px;
               span{

               }
           }
           .desc{
               font-size: 26px;
           }
       }
       .scroll-div{
           position: relative;
           z-index: 8;
       }
       .list-div{
           box-sizing: border-box;
           width: 100%;

           height: 100%;
           background: #f7f7f7;
           border-radius: 24px 24px 0 0;
           position: relative;
           /*overflow-x: hidden;*/
         /*min-height: calc(100vh - 140px);*/
         opacity: 0;
         &.showDiv{
           opacity: 1;
         }
           .tabs{
               border-radius: 24px 24px 0 0;
               padding: 32px 32px 0 32px;
              display: flex;
               /*margin-bottom: 24px;*/
               background: white;
               .tab{
                   margin-right: 46px;
                   color: #9A9A9A;
                   &.selected{
                   color: #000000;
                       padding-bottom: 8px;
                       font-weight: 500;
                       border-bottom: 6px solid #88A3CC ;
                     }
               }
           }
           .list-out{
             position: relative;
               width: 100%;
               display: flex;
             height: 100%;
             bottom: 10px;

             /*min-height: 200px;*/
               /*flex-wrap: wrap;*/
             /*.topic-list-water{*/
               /*width: 50%;*/
             /*}*/
             .topic-list-water{
               display: inline-block;
               position: absolute;
               /*float: left;*/
             }
               .topic-list-left,.topic-list-right{

                   padding:0 16px 16px 16px;
                   width: 50%;
                   .text-div{
                       padding: 16px;
                   }
                   .img{
                       width: 100%;
                       border-radius: 24px;
                       /*max-height: 372px;*/
                   }
                   .content-div{
                       position: relative;
                       background: #ffffff;
                       margin-bottom: 18px;

                       .play{
                           position: absolute;
                           right: 16px;
                           top: 18px;
                           width: 48px;
                           height: 48px;
                       }
                       .desc{
                           font-size: 28px;
                           color: #333333;
                        }
                       .tags{
                           margin: 16px 0;
                           background: #B2B2B2;
                           opacity: 0.39;
                           padding: 4px 16px;
                           border-radius: 20px;
                           font-size: 22px;
                       }
                       .user{
                           display: flex;
                           justify-content: space-between;
                           align-items: center;
                           .avatar{
                             border-radius: 50%;
                           }
                           .name{
                               font-size: 20px;
                               color: #B2B2B2;
                           }
                           img{
                               width: 44px;
                               height: 44px;
                           }
                       }
                   }
               }
           }

       }

   }

</style>
