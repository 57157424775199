<template>
  <div>
    <div style="background-color: white;text-align: left;height: 100%;user-select: none;">
      <header
        v-if="false"
        class="header"
      >集宠区</header>

      <div style="position: fixed;width: 100%;z-index: 10;top:0">
        <down-tip
          :info="open_data.data"
          @openShare="openAppUrl"
        />
      </div>
      <div
        v-if="false"
        class="mt-60"
      >&nbsp;</div>
      <div class="top-height"></div>

      <share-moment
        v-if="share_type == 1 &&info.data&& info.data.dynamicId>0"
        :info="info.data"
      />
      <share-pet
        v-if="share_type==2 &&info.data&& info.data.spuId"
        :info="info.data"
      />
      <share-article
        v-if="share_type == 3 && info.data&& info.data.articleId>0"
        :info="info.data"
      />
      <share-user
        v-if="share_type == 4 && open_data.data.id>0"
        :uid="open_data.data.id"
      />
      <share-store
        v-if="share_type == 5 && info.data&&info.data.spuId"
        :info="info.data"
      />
      <share-video
        v-if="share_type == 6 &&info.data&& info.data.dynamicId>0"
        :info="info.data"
      />
      <share-topic
        v-if="share_type == 7 &&info.data&&infoTwo.data&& info.detail_info &&info.detail_info.topicId>0"
        :info-two="infoTwo.data"
        :info="info.data"
        :detail-info="info.detail_info"
      />
      <share-chat
        v-if="share_type == 8 && info.userInfo&&info.data &&info.data.groupId>0"
        :user-info="info.userInfo"
        :info="info.data"
        @joinEvent="openAppUrl"
      />
      <share-q-answer
        v-if="share_type == 9 && info.data && info.data.problemId>0"
        :info="info.data"
      />
      <share-shop
        v-if="share_type == 10 && info.data && info.data.shopId>0"
        :info="info.data"
      />

      <zc-down v-if="share_type == 0" />
    </div>
    <div
      v-if="share_type != 8"
      class="open-bottom "
    >
      <div
        class="ft-28 pt-16 pb-16 pl-32 pr-32 open-btn"
        @click="openAppUrl"
      >集宠区内打开</div>
    </div>
    <open-tip v-if="show" />
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import DownTip from '@/views/share/component/DownTip'
import ShareMoment from '@/views/share/component/ShareMoment'
import SharePet from '@/views/share/component/SharePet'
import ShareArticle from '@/views/share/component/ShareArticle'
import OpenTip from '@/components/OpenTip'
import ShareUser from '@/views/share/component/ShareUser'
import ShareStore from '@/views/share/component/ShareStore'
import ShareVideo from '@/views/share/component/ShareVideo'
import ShareTopic from '@/views/share/component/ShareTopic'
import ShareChat from '@/views/share/component/ShareChatGroup'
import ShareQAnswer from '@/views/share/component/ShareQAnswer'
import ShareShop from '@/views/share/component/ShareShop.vue'
import { useRouter } from 'vue-router'
import {
  comArticleDetail, momentDetail,
  petDetail, comGoodsStoreDetail, chatRoom,
  qAnswerDetail, qAnswerChat, momentList, topicDetail, userInfo
} from '@/api/share'
import { Toast } from 'vant'

import { openApp } from '@/utils/share'
import { getPhoneBrand, isAndroid, isWeiBo, isWx } from '@/utils/phonesys'
import ZcDown from '@/components/ZcDown'
import { getShopHome } from '@/api/supplier_server'
import { setToken } from '@/utils/auth'

export default defineComponent({
  name: 'ShareView',
  components: {
    DownTip,
    ShareMoment,
    SharePet,
    ShareArticle,
    OpenTip,
    ShareUser,
    ShareStore,
    ShareVideo,
    ShareTopic,
    ShareChat,
    ZcDown,
    ShareQAnswer,
    ShareShop
  },

  setup() {
    let share_type = ref(0)
    const open_data = reactive({})
    let show_header = ref(false)
    const info = reactive({})
    const infoTwo = reactive({})
    const show = ref(false)
    const sid = ref(0)
    const $router = useRouter()
    setToken('')
    const query = $router.currentRoute.value.query

    if (query && query.moment) {
      share_type = 1
      // 展示动态详情页面
      open_data.data = Object.assign(query, { key: 'moment', id: query.moment })
      const params = {
        dynamicId: query.moment
      }
      momentDetail(params).then(res => {
        info.data = res.data[0]
        console.log(info.data)
      })
    }
    if (query && query.pet) {
      share_type = 2
      // 展示宠物详情页面
      open_data.data = Object.assign(query, { key: 'pet', id: query.pet, aid: query.aid ? query.aid : '',
        arid: query.arid ? query.arid : '', skuid: query.skuid ? query.skuid : '', type: query.type ? query.type : '' })
      const params = {
        spuId: query.pet,
        activityId: query.aid,
        activityRuleId: query.arid,
        platformType: 1
      }
      petDetail(params).then(res => {
        info.data = res.data[0]
        info.data.skuId = query.skuid
      }).catch(res => {

      })
    }

    if (query && query.article) {
      share_type = 3
      // 展示官方文章详情页面
      open_data.data = Object.assign(query, { key: 'article', id: query.article })
      const params = {
        articleId: query.article
      }
      comArticleDetail(params).then(res => {
        info.data = res.data[0]
      })
    }
    if (query && query.com_article) {
      share_type = 3
      // 展示官方文章详情页面
      open_data.data = Object.assign(query, { key: 'com_article', id: query.com_article })
      const params = {
        articleId: query.com_article
      }
      comArticleDetail(params).then(res => {
        info.data = res.data[0]
      })
    }

    if (query && query.user) {
      share_type = 4
      // 展示官方文章详情页面
      open_data.data = Object.assign(query, { key: 'user', id: query.user })
      sid.value = query.user
    }

    if (query && query.store_goods) {
      share_type = 5
      // 展示商城详情页面
      open_data.data = Object.assign(query, { key: 'store_goods', id: query.store_goods,
        aid: query.aid ? query.aid : '', skuid: query.skuid ? query.skuid : '',
        arid: query.arid ? query.arid : '', type: query.type ? query.type : '' })
      const params = {
        spuId: query.store_goods,
        activityId: query.aid,
        activityRuleId: query.arid,
        platformType: 1
      }
      comGoodsStoreDetail(params).then(res => {
        info.data = res.data[0]
        info.data.skuId = query.skuid
        console.log(info.data, 'info.data1111111')
      })
    }
    if (query && query.video_play) {
      share_type = 6
      // 展示话题视频详情页面

      open_data.data = Object.assign(query, { key: 'video_play', id: query.video_play })
      // comGoodsStoreDetail(query.store_goods).then(res => {
      //   info.data = res.data[0]
      // })
      const params = {
        dynamicId: query.video_play
      }
      momentDetail(params).then(res => {
        info.data = res.data[0]
      })
    }
    if (query && query.topic_detail) {
      share_type = 7
      // 展示话题视频列表页面
      open_data.data = Object.assign(query, { key: 'topic_detail', id: query.topic_detail })
      const params = {
        page_size: 20,
        page_number: 1,
        latest: true
      }
      topicDetail(query.topic_detail).then((res) => {
        info.detail_info = res.data[0]
      })
      momentList(params).then(res => {
        info.data = res.data
        console.log(info, '88888')
      })
      const paramsTwo = {
        page_size: 20,
        page_number: 1,
        hottest: true
      }
      momentList(paramsTwo).then(res => {
        infoTwo.data = res.data
      })
    }
    if (query && query.chat_room) {
      share_type = 8
      // 群聊
      open_data.data = Object.assign(query, { key: 'chat_room', id: query.chat_room })
      const roomParams = {
        groupId: query.chat_room
      }
      chatRoom(roomParams).then(res => {
        info.data = res.data[0]
      })
      const params = {
        userId: query.user_id
      }
      userInfo(params).then(res => {
        info.userInfo = res.data[0]
      })
    }
    if (query && query.q_answer) { // 问答
      share_type = 9

      // 展示动态详情页面

      open_data.data = Object.assign(query, { key: 'q_answer', id: query.q_answer })
      const params = {
        problemId: query.q_answer
      }
      qAnswerDetail(params).then(res => {
        info.data = res.data[0] || {}
        info.data.list = []
        const param = {
          problemId: query.q_answer,
          page_number: 1,
          page_size: 20
        }
        qAnswerChat(param).then(res => {
          info.data.list = res.data
        })
      })
    }
    if (query && query.shop) { // 店铺
      share_type = 10

      // 展示品牌详情页面

      open_data.data = Object.assign(query, { key: 'shop', id: query.shop })
      getShopHome(query.shop, { shared: true }).then(res => {
        info.data = res.data[0]
      })
    }
    if (query && query.show) { show_header = query.show }
    const onChange = (index) => Toast('当前 Swipe 索引：' + index)

    const openAppUrl = () => {
      if ((isWx() && isAndroid()) || isWeiBo() || getPhoneBrand() == 'qq') {
        show.value = true
      } else {
        openApp(open_data.data)
      }
    }

    return {
      sid,
      show,
      open_data,
      openAppUrl,
      share_type,
      show_header,
      info,
      infoTwo,
      onChange
    }
  }
})
</script>

<style scoped>
  .top-height {
    height: 134px;
  }

  .header {
    z-index: 10;
    text-align: center;
    top: 0;
    height: 60px;
    line-height: 60px;
    width: 100%;
    font-size: 18px;
    background-color: white;
    position: fixed;
  }

  .content {
    margin-bottom: 60px;
  }

  footer {
    background-color: white;
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
  }

  .open-bottom {
    position: fixed;
    bottom: 120px;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 8;
  }

  .open-btn {
    text-align: center;
    width: 234px;
    background-color: #88A3CC;
    color: white;
    border-radius: 36px;
  }

</style>
