<template>
  <div>
    <van-overlay show="true" z-index="20"/>
    <img src="/static/image/tip_s.png" class="open-tip-t">
    <div class="open-tip">
      <div class="open-tip-text pl-16 pr-16">
        <div>点击右上角…按钮</div>
        <div>选择在浏览器打开</div>
      </div>

    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "OpenTip",
  setup() {

  }
})
</script>

<style scoped>

.open-tip-t {
  z-index: 32;
  position: fixed;
  top: 8px;
  right: 58px;
  width: 24px;
  height: 16px;
}

.open-tip {
  position: fixed;
  top: 24px;
  right: 32px;
  background-color: #88A3CC;
  width: 256px;

  z-index: 33;
  border-radius: 8px;
  padding: 32px 0px 32px 0px;

}

.open-tip-text {
  font-size: 28px;
  color: white;
}

</style>
