import axios from 'axios'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://ecs2.gpets.cn/',
  //   baseURL: 'https://d.gpets.cn/',
  timeout: 15000
})

// request interceptor
service.interceptors.request.use(

  config => {
    if (getToken) {
      config.headers['Authorization'] = getToken()
    }
    if (config.url.indexOf('api/buried_point') != -1) {
      config.headers['pointAppKey'] = 'Dmq2nnDTnpyZBNEhy6IC'
      config.headers['device'] = 'H5'
      config.headers['v'] = '2.14.1'
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    console.log('err' + error) // for debug

    return Promise.reject(error)
  }
)

export default service
