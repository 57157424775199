<template>
  <div class="bg-chat">
    <div class="content-chat">
      <div class="title flex flex-v-c">
        <img
          :src="user_info.avatar?user_info.avatar:'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nTkuEN5PhFQOjnRCHox5.jpg'"
          alt=""
        >
        <span>{{ user_info.nickname||'集宠区' }}</span>
      </div>
      <div class="ft-32 mb-24 mt-24">邀请您一起来参与群聊</div>
      <img
        class="bg-img"
        :src="data_info.backgroundUrl?data_info.backgroundUrl:'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nTkuEN5PhFQOjnRCHox5.jpg'"
        alt=""
      >
      <div class="chat-name">{{ data_info.groupName||'集宠区' }}</div>
      <div class="num">{{ data_info.chatQuantity }}人正在一起聊...</div>
      <div
        class="btn-out"
        @click="joinEvent"
      ><span class="btn">立即参与</span></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ShareMoment',
  props: ['info', 'userInfo'],
  setup(props, { emit }) {
    const data_info = ref(props.info)
    const user_info = ref(props.userInfo)
    const joinEvent = () => {
      emit('joinEvent')
    }
    return {
      data_info,
      user_info,
      joinEvent
    }
  }
})
</script>

<style scoped lang="scss">

  .bg-chat {
    width: 750px;
    height: calc(100vh - 342px);
    background: #88A3CC;

    padding-top: 210px;
    display: flex;
    justify-content: center;
    .content-chat{
      padding: 48px;
      width: 552px;
      height: 746px;
      background: #ffffff;
      opacity: 1;
      border-radius: 8px;

      .title{
        font-size: 36px;
        color: #333333;
        font-weight: bold;
        img{
          width: 96px;
          height: 96px;
          margin-right: 26px;
        }
      }
      .bg-img{

        height: 290px;
      }
      .chat-name{
        font-size: 36px;
        color: #333333;
        margin: 32px 0 16px 0;
        font-weight: bold;
      }
      .num{
        color: #9A9A9A;
        font-size: 28px;
        margin-bottom: 32px;
      }
      .btn-out{
        text-align: center;
        .btn{
          display: inline-block;
          background: #1A1A1A ;
          color: white;
          border-radius: 46px;
          width: 248px;
          height: 72px;
          line-height: 72px;
          font-size: 28px;
        }
      }
    }
  }

</style>
