import defaultImg from '@/assets/logo.png'
// 异步加载图片
const imageAsync = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = () => {
      resolve()
    }
    img.onerror = (err) => {
      reject(err)
    }
  })
}

export const lazyPlugin = {
  install(app) {
    app.directive('lazy', {
      mounted(el, binding) {
        // el.src = defaultImg
        el.src = ''

        const io = new IntersectionObserver((entries) => {
          entries.forEach((item) => {
            if (item.isIntersecting) {
              // 使用异步加载图片
              imageAsync(binding.value)
                .then(() => {
                  el.src = binding.value// 成功后再替换 img 标签的 src
                })
                .catch((error) => {
                  console.log(error)
                })
              io.unobserve(item.target)
            }
          })
        })
        io.observe(el)
      }
    })
  }
}
