
const state = {

  scrollHeight: 0

}

const mutations = {

  SETHIEGHT: (state, scrollHeight) => {
    state.scrollHeight = scrollHeight
  }
}
const actions = {
  setScrollHeight({ commit }, headerColor) {
    commit('SETHIEGHT', headerColor)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
