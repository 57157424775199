import { createRouter, createWebHistory } from 'vue-router'

import DownView from '@/views/down/DownView'
import ShareView from '@/views/share/ShareView'

import yszc from '@/views/yszc'
import yhxy from '@/views/yhxy'

const mobile_redirect = function() {
  const device = navigator.userAgent.toLowerCase()

  return /(iPhone|iPad|iPod|iOS|Android|Linux armv8l|Linux armv7l|Linux aarch64)/i.test(navigator.platform) || window.innerWidth < 800

  // if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(device)) {
  //   return true
  // }
  //
  // return false

  // const device = navigator.userAgent.toLowerCase()
}

const routes = [
  {
    path: '/',
    name: 'index',
    // component: WebsitePcMain,
    component: () => import(/* webpackChunkName: "WebsitePcMain" */ '../views/websitePcMain/index.vue'),
    redirect: '/index',
    // redirect: mobile_redirect(),
    meta: {
      title: '集宠官网'
    },
    children: [
      { name: 'index', path: '/index', component: () => import(/* webpackChunkName: "index" */ '@/views/websitePc/index') },
      { name: 'active', path: '/active', component: () => import(/* webpackChunkName: "active" */ '@/views/websitePc/active') },
      { name: 'merchant', path: '/merchant', component: () => import(/* webpackChunkName: "merchant" */ '@/views/websitePc/merchant') },
      { name: 'personMerchant', path: '/personMerchant', component: () => import(/* webpackChunkName: "personMerchant" */ '@/views/websitePc/personMerchant') },
      { name: 'aboutUs', path: '/aboutUs', component: () => import(/* webpackChunkName: "aboutTwo" */ '@/views/websitePc/about') }
    ]
  },
  {
    path: '/active_vue/luck_draw', // 扎金蛋活动
    name: 'luck_draw',

    component: () => import(/* webpackChunkName: "luckDraw" */ '../views/appActives/luckDraw/index.vue')

  },
  {
    path: '/active_vue/turntable_award', // 大转盘半屏app积分抽奖
    name: 'turntable_award',

    component: () => import(/* webpackChunkName: "luckDraw" */ '../views/appActives/turntable_award/index.vue')

  },
  {
    path: '/turntable_award_record',
    name: 'turntable_award_record',

    component: () => import(/* webpackChunkName: "luckDraw" */ '../views/appActives/turntable_award/record.vue')

  },
  {
    path: '/active_vue/luck_draw_mini',
    name: '/active_vue/luck_draw_mini',

    component: () => import(/* webpackChunkName: "luckDraw" */ '../views/miniAppActive/luckDraw/index.vue')

  },
  {
    path: '/role_mini',
    name: 'role_mini',

    component: () => import(/* webpackChunkName: "role" */ '../views/miniAppActive/luckDraw/role.vue')

  },
  {
    path: '/role',
    name: 'role',

    component: () => import(/* webpackChunkName: "role" */ '../views/appActives/luckDraw/role.vue')

  },
  {
    path: '/wheel_role',
    name: 'wheel_role',

    component: () => import(/* webpackChunkName: "role" */ '../views/appActives/turntable_award/role.vue')

  },

  {
    path: '/yszc',
    name: 'yszc',
    component: yszc
  },
  {
    path: '/yhxy',
    name: 'yhxy',
    component: yhxy
  },
  {
    path: '/gpets',
    name: 'gpets',
    component: DownView,
    meta: {
      title: '集宠区App下载'
    }
  },
  {
    path: '/down',
    name: 'down',
    component: DownView,
    meta: {
      title: '集宠区App下载'
    }
  },
  {
    path: '/share',
    name: 'share',
    component: ShareView,
    meta: {
      title: '集宠区'
    }
  },

  {
    path: '/help_sort',
    name: 'help_sort',

    component: () => import(/* webpackChunkName: "about" */ '../views/appActives/helpSort/index.vue')
  },
  {
    path: '/help_sort_role',
    name: 'help_sort_role',
    component: () => import(/* webpackChunkName: "about" */ '../views/appActives/helpSort/role.vue')
  },
  {
    path: '/appIntroduce',
    name: 'appIntroduce',
    component: () => import(/* webpackChunkName: "about" */ '../views/appActives/appIntroduce/index.vue')
  },
  {
    path: '/goldenEggs',
    name: 'goldenEggs',

    component: () => import(/* webpackChunkName: "about" */ '../views/appActives/goldenEggs/index.vue')
  },
  {
    path: '/inviteJob',
    name: 'inviteJob',

    component: () => import(/* webpackChunkName: "h5" */ '../views/inviteJob/index.vue')
  }, {
    path: '/websitePhone',
    name: 'websitePhone',

    component: () => import(/* webpackChunkName: "h5" */ '../views/websitePhone/index.vue')
  },
  {
    path: '/page_manage',
    name: 'page_manage',
    component: () => import(/* webpackChunkName: "h5" */ '../views/page_manage/index.vue')
  },
  {
    path: '/p_s',
    name: 'p_s',
    component: () => import(/* webpackChunkName: "h5" */ '../views/page_manage_share/index.vue')
  },
  {
    path: '/gpetsActive',
    name: 'gpetsActive',
    component: () => import(/* webpackChunkName: "h5" */ '../views/appActives/gpetsActive/index.vue')
  },
  {
    path: '/active_vue/webview_vue_common_active',
    name: '/webview_vue_common_active',
    component: () => import(/* webpackChunkName: "luckDraw" */ '../views/miniAppActive/webview_vue_common_active/index.vue')

  },

  {
    path: '/s_c',
    name: 's_c',

    component: () => import(/* webpackChunkName: "h5" */ '../views/share_manage/share_coupon.vue')
  },
  {
    path: '/s_g', // 公共分享页面
    name: 's_g',

    component: () => import(/* webpackChunkName: "h5" */ '../views/share_manage/active_share_global.vue')
  },
  {
    path: '/invite_gift', // inviteNewUserGift
    name: 'invite_gift',

    component: () => import(/* webpackChunkName: "invite_gift" */ '../views/appActives/inviteNewUserGift/index.vue')
  },
  {
    path: '/s_i_g', // 新人礼分享
    name: 's_i_g',

    component: () => import(/* webpackChunkName: "h5" */ '../views/share_manage/share_invite_gift.vue')
  },
  {
    path: '/creatorActive', // creatorActive创作者活动
    name: 'creatorActive',

    component: () => import(/* webpackChunkName: "h5" */ '../views/appActives/creatorActive/index.vue')
  },
  {
    path: '/masterDesc', // creatorActive创作者活动
    name: 'masterDesc',

    component: () => import(/* webpackChunkName: "h5" */ '../views/appActives/activeDesc/master/masterDesc.vue')
  },
  {
    path: '/contentDesc', // creatorActive创作者活动
    name: 'contentDesc',

    component: () => import(/* webpackChunkName: "h5" */ '../views/appActives/activeDesc/master/contentDesc.vue')
  },
  {
    path: '/gpets_xy', // creatorActive创作者活动
    name: 'gpetsXy',

    component: () => import(/* webpackChunkName: "h5" */ '../views/gpets_xy/index.vue')
  },
  {
    path: '/creatorDesc', // creatorActive创作者活动
    name: 'creatorDesc',
    meta: {
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "h5" */ '../views/appActives/activeDesc/creatorDesc/creatorDesc.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 修改title
router.beforeEach((to, from, next) => {
  if (to.meta?.title) {
    document.title = to.meta.title // 通过document.title赋值
  }

  if (to.path === '/index' && mobile_redirect()) {
    next({
      path: '/websitePhone'
    })
  } else {
    next()
  }
})

export default router
