<template>
  <div class="yszc-div">
    <div
      class="content"
      v-html="data"
    ></div>

  </div>
</template>

<script setup>

import { getAgreement } from '@/api/down'
import { ref } from 'vue'
const data = ref('')
const getAgreementEvent = () => {
  getAgreement(2).then(res => {
    data.value = res.data && res.data[0].content
  })
}
getAgreementEvent()
</script>
<style   lang="scss"  scoped>

  .yszc-div{
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 10px;
    text-align: left;
    color: black;
  }
</style>

