<template>

  <div>
    <zc-down></zc-down>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import ZcDown from "@/components/ZcDown";

export default defineComponent({
  name: "DownView",
  components:{ZcDown},
  setup() {

  }
})
</script>

<style scoped>

</style>
