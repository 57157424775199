<template>
  <div
    v-if="!loading"
    class="content-bg"
  >
    <div
      class="contents"
      style="padding-left: 0;padding-right: 0"
    >
      <div class="mb-58">
        <img
          src="/static/image/logo.png"
          style="width: 22%"
        >
      </div>
      <div>
        <span class="ft-44">集宠区</span>
      </div>
      <div>
        <span class="ft-28">全类别宠物交易平台</span>
      </div>

      <div class="bottom">
        <a
          v-if="!is_ios"
          class="btn"
          @click="appDown(2)"
        >
          <img
            class="img-ios"
            src="/static/image/android.png"
          >
          <span
            class="ft-32"
            style="font-weight: 500;color:#000;"
          >Android下载</span>
        </a>
        <!--<a v-if="!is_android" class="btn"  @click="appDown(1)">-->
        <a
          v-if="!is_android"
          class="btn"
          :href="ios_down_url"
          @click="appDown(1)"
        >
          <img
            class="img-ios"
            src="/static/image/ios.png"
          >
          <span
            class="ft-32"
            style="font-weight: 500;color:#000;"
          >iOS下载</span>
        </a>
      </div>
    </div>
  </div>
  <open-tip v-if="show" />
</template>

<script>
// import {ShareTrace} from "@/utils/sharetrace.js";
import { defineComponent, ref, reactive } from 'vue'
import { getPhoneBrand, isAndroid, isWeiBo, isWx } from '@/utils/phonesys'
import { useRouter } from 'vue-router'
import { scanCount, getDownLoadUrl } from '@/api/down'
import { isIOS } from 'vant/es/utils'
import OpenTip from '@/components/OpenTip'
import { openApp } from '@/utils/share'
// import ShareTrace from "@babel/eslint-parser/lib/worker/babel-core";

export default defineComponent({
  name: 'ZcDown',
  components: {
    OpenTip
  },
  setup: function() {
    const is_ios = ref(true)
    const is_android = ref(true)
    const show = ref(false)
    const loading = ref(true)
    const ios_down_url = 'https://apps.apple.com/cn/app/id1571447569'
    const and_down_url = ref('')
    const isInitSuccess = false
    const $router = useRouter()
    const query = $router.currentRoute.value.query

    // if (query && query.is_share) {

    //   const index = location.href.indexOf('?')
    //   const share_code_index = query.share_code ? query.share_code.indexOf(',') : ''

    //   const share_code = share_code_index != -1 ? query.share_code.slice(0, share_code_index) : query.share_code
    //   // let
    //   const url = location.href.slice(0, index) + '?no_header=1&activity=' + query.activity + '&ct=' + query.ct + '&share_code=' + share_code + '&other_key=11'

    //   open_data.data = Object.assign(query, { key: '', url })
    // }else{
    //   open_data.data = Object.assign(query, { key: '' })
    // }
    // 黄总名片单独判断:

    // if (query && query.f && query.f == 2) {
    //   window.location.href ='https://work.weixin.qq.com/wework_admin/user/h5/wx_add_friend/8928628be1180eca'
    //   return
    // } else {
    loading.value = false
    // }
    // 获取邀请人: 8aCnUz01O
    // let share_code = ""
    // if (query && query.share_code ) {

    //   share_code = query.share_code;
    // }

    // ShareTrace.init({
    //   appkey: 'e803d08087cd788a', // 分配的appkey
    //   // param:'share_code='+share_code,
    //   success: function() {
    //     isInitSuccess = true
    //   },
    //   error: function(msg) {
    //     console.log('lose')
    //     console.log('ShareTrace:', msg)
    //   }
    // })

    // 判断是在安卓还是ios打开

    is_ios.value = isIOS()
    is_android.value = isAndroid()

    const showBrand = () => {
      // alert(getPhoneBrand())
    }
    const appDown = (phone) => {
      // if ((isWx() && isAndroid()) || isWeiBo()) {
      console.log('11111111执行了。。。。。。')
      if (isWx() || isWeiBo()) {
        show.value = true
        return
      }
      if (isInitSuccess) {
        // ShareTrace.download()
      } else {
        location.href = and_down_url.value
      }

      console.log('///////强制跳转')
      console.log(query)

      // if (query && (query.share_code||query.store_goods)) {
      //   ShareTrace.download()
      //   return
      //   console.log(open_data.data)
      //
      //   //
      //   openApp(open_data.data, callback => {
      //     console.log(callback)
      //
      //     if (isAndroid) {
      //       if (callback == 'failure') {
      //         if (isInitSuccess) {
      //           console.log('安卓chenggong')
      //           ShareTrace.download()
      //           // openApp(open_data.data)
      //         } else {
      //           console.log('失败')
      //           // 如果初始化失败建议自己指定下载地址，虽然不能获取安装参数但是不会影响用户的下载
      //           window.location.href = 'https://d.gpets.cn/down?f=100'
      //         }
      //       }
      //     } else {
      //       if (isInitSuccess) {
      //         console.log('苹果chenggong')
      //         ShareTrace.download()
      //         // openApp(open_data.data)
      //       } else {
      //         console.log('失败')
      //         // 如果初始化失败建议自己指定下载地址，虽然不能获取安装参数但是不会影响用户的下载
      //         window.location.href = 'https://d.gpets.cn/down?f=100'
      //       }
      //     }
      //   })
      //
      // }
      //
      // else if (query.store_goods) { // 商城首页
      //   openApp({})
      //   console.log('zhixingle首页')
      // }
      scanCount({ phone: phone, model: getPhoneBrand(), f: query.f }).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    }
    const getDownLoadUrlEvent = () => {
      getDownLoadUrl({ config_name: '安卓下载地址' }).then(res => {
        console.log('下载地址', res.data[0] && res.data[0].str_value)
        and_down_url.value = res.data[0] && res.data[0].str_value
      }).catch(err => {
        console.log(err)
      })
    }
    getDownLoadUrlEvent()
    return {
      loading,
      show,
      is_ios,
      is_android,
      ios_down_url,
      and_down_url,
      showBrand,
      appDown
    }
  }
})
</script>

<style scoped>
.content-bg {
  position: fixed;
  bottom: 0px;
  top:0px;
  left: 0px;
  right: 0px;
  background-color: rgba(141,162,200,0.95);
  color: white;
  z-index: 11;
}

.contents {
  text-align: center;
  padding-top: 200px;
  /*background-color: rgba(141,162,200,0.6);*/

}

.btn {
  background-color: white;
  width: 400px;
  height: 84px;
  border-radius: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  text-decoration: none;
}

.img-ios {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.bottom {
  position: fixed;
  bottom: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

</style>
