import { createStore } from 'vuex'
import getters from './getters'
import website from './modules/website'
import scrollSaveModule from './modules/scrollSaveModule'
const store = createStore({
  modules: {
    website,
    scrollSaveModule

  },
  getters
})

export default store
