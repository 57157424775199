import CallApp from 'callapp-lib'

const option = {
  scheme: {
    protocol: 'gpets',
    host: 'cn.gpets.app'
  },
  intent: {
    package: 'cn.gpets.app',
    scheme: 'gpetscheme'
  },
  universal: {
    host: 'www.gpets.cn',
    pathKey: ''
  },
  appstore: 'https://d.gpets.cn/down?f=100',
  yingyongbao: 'https://d.gpets.cn/down?f=100',
  fallback: 'https://d.gpets.cn/down?f=100',
  timeout: 15000// 等待时长
}

const dict2query = (dict) => {
  if (!dict) return ''
  let ret_str = ''
  Object.keys(dict).some((key) => {
    ret_str += ('&' + key + '=' + dict[key])
  })
  return ret_str
}

export const openApp = (info, callback) => {
  info.timestamp = parseInt(new Date().getTime() / 1000)
  const url = dict2query(info)
  option.yingyongbao += url
  option.fallback += url
  option.appstore += url
  option.logFunc = callback

  const lib = new CallApp(option)
  // 兼容安卓QQ浏览器 打开时,出现系统级缓存的问题
  info.timestamp = parseInt(new Date().getTime() / 1000)
  lib.open({
    param: info,
    path: 'gpets'
  })
}
