
import requestJava from '@/utils/requestJava'
export function momentDetail(params) {
  return requestJava({
    url: `community-server/app-api/communityDynamic/v1/detail`,
    method: 'get',
    params
  })
}

export function petDetail(data) {
  return requestJava({
    url: '/product-server/app-api/petProductSpu/detail',
    method: 'post',
    data
  })
}
export function petList(data) {
  return requestJava({
    url: '/product-server/app-api/petProductSpu/v1/selectList',
    method: 'post',
    data
  })
}

export const comArticleDetail = (params) => {
  return requestJava({
    url: `/activity-server/app-api/advertArticle/v1/getArticle`,
    method: 'get',
    params
  })
}
export const comGoodsStoreDetail = (data) => {
  return requestJava({
    url: `/product-server/app-api/mallProductSpu/detail`,
    method: 'post',
    data
  })
}
export const comGoodsLikelist = (data) => {
  return requestJava({
    url: '/product-server/app-api/mallProductSpu/selectRelatedSpus',
    method: 'post',
    data
  })
}

export const userInfo = (params) => {
  return requestJava({
    url: `/user-server/app-api/userBasicInfo/v1/homePage`,
    method: 'get',
    params
  })
}
export const userInfoById = (params) => {
  return requestJava({
    url: `/user-server/app-api/userBasicInfo/v1/getUserBasicById`,
    method: 'get',
    params
  })
}
export const momentList = (data) => {
  return requestJava({
    url: `/community-server/app-api/communityDynamic/v1/selectList`,
    method: 'post',
    data
  })
}

export const topicDetail = (topicId) => {
  return requestJava({
    url: `/community-server/app-api/communityTopic/v1/detail/${topicId}`,
    method: 'get'
  })
}

export const chatRoom = (params) => {
  return requestJava({
    url: `/community-server/app-api/communityGroup/v1/detail`,
    method: 'get',
    params
  })
}

export const qAnswerDetail = (params) => {
  return requestJava({
    url: `/community-server/app-api/communityGroupProblem/v1/detail`,
    method: 'get',
    params
  })
}
export const qAnswerChat = (data) => {
  return requestJava({
    url: `/community-server/app-api/communityGroupProblem/v1/queryProblemReviewed`,
    method: 'post',
    data
  })
}
export const scanCouponInfo = (couponId, data) => {
  return requestJava({
    url: `activity-server/app-api/voucherCenter/v1/scanCode/coupon/info/${couponId}`,
    method: 'get'
  })
}
