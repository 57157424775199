<template>
  <div class="pet-detail">
    <video
      v-if="data_info.defaultVideoUrl"
      style="width: 375px;height: 375px;"
      :src="data_info.defaultVideoUrl"
      :poster="data_info.defaultVideoUrl+'?x-oss-process=video/snapshot,t_1,f_jpg'"
      :controls="true"
      :show-center-play-btn="false"
      auto-pause-if-navigate
      object-fit="cover"
    />
    <img
      v-else
      class="pet-img"
      :src="chooseSpecInfo.skuPictureUrls[0]"
      alt=""
    >
    <div class="pic-list">
      <img
        v-for="(m,i) in data_info.skus"
        :key="i"
        alt=""
        :src="m.specificationPictureUrl"
        :class="[selected==i?'selected':'']"
      >
    </div>
    <div class="pl-32 pr-32 shopInfo">
      <div
        v-if="chooseSpecInfo.activity&&chooseSpecInfo.activity.activityPriceType==1"
        class="flex flex-bt pt-10 pb-10"
      >
        <!--            新人活动-->
        <div class="left">
          <div>

            <span
              class="money-text"
              style="color: #8BA3C7"
            >￥<span class="money">
              {{ chooseSpecInfo.activity?chooseSpecInfo.activity.activityPrice:chooseSpecInfo.salePrice=='0'?'--':chooseSpecInfo.salePrice }}
            </span> {{ chooseSpecInfo.activity?'新人价':'优惠价' }}  </span>
            <span class="discount_money">￥{{ chooseSpecInfo.originalPrice }}</span>
          </div>

        </div>
        <div class="right">
          <span class="pay">{{ data_info.buyQuantity }}人付款
          </span>
          <span class="get">{{ data_info.likedQuantity }}人想要
          </span>
        </div>
      </div>
      <div
        v-else-if="chooseSpecInfo.activity&&chooseSpecInfo.activity.activityPriceType==2"
      >
        <!--            限时秒杀  未结束-->
        <div
          v-if="chooseSpecInfo.activity&&chooseSpecInfo.activity.countdown"
          class="flex   limit-time-div"
        >
          <div class="left">
            <div class=" flex flex-h-c flex-v-c">

              <span
                class="money-text"
                style="color: #8BA3C7"
              >￥<span class="money">
                {{ chooseSpecInfo.activity?chooseSpecInfo.activity.activityPrice:chooseSpecInfo.salePrice=='0'?'--':chooseSpecInfo.salePrice }}
              </span> {{ chooseSpecInfo.activity?'秒杀价':'优惠价' }}  </span>
              <span class="stock">仅剩{{ chooseSpecInfo.currentStockQuantity }}件</span>
            </div>
            <div class=" flex flex-v-c mt-8 pl-20">
              <span class="pay">{{ data_info.buyQuantity }}+人付款
              </span>
              <span class="get ml-24">{{ data_info.likedQuantity }}人想要
              </span>
            </div>

          </div>
          <div class="limit-time-view">
            <!--                <img-->
            <!--                  src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1782955162374119424.png"-->
            <!--                  alt=""-->
            <!--                >-->
            <div>限时秒杀</div>
            <div class="count-down flex flex-v-c">
              <van-count-down

                v-if="chooseSpecInfo.activity&&chooseSpecInfo.activity.countdown"
                :time="chooseSpecInfo.activity.countdown*1000"
                format="HH:mm:ss"
              />后结束
            </div>

          </div>
        </div>
        <div
          v-else
          class="flex flex-bt pt-10 pb-10"
        >
          <div class="left">
            <div>

              <span
                class="money-text"
                style="color: #8BA3C7"
              >￥<span class="money">
                {{ chooseSpecInfo.salePrice=='0'?'--':chooseSpecInfo.salePrice }}
              </span> 优惠价 </span>
              <span class="discount_money">￥{{ chooseSpecInfo.originalPrice }}</span>
            </div>

          </div>
          <div class="right">
            <span class="pay">{{ data_info.buyQuantity }}人付款
            </span>
            <span class="get">{{ data_info.likedQuantity }}人想要
            </span>
          </div>
        </div>
      </div>
      <div
        v-else
      >
        <!--            常规商品展示-->

        <div

          class="flex flex-bt pt-10 pb-10"
        >
          <div class="left">
            <div>

              <span
                class="money-text"
                style="color: #8BA3C7"
              >￥<span class="money">
                {{ chooseSpecInfo.salePrice=='0'?'--':chooseSpecInfo.salePrice }}
              </span> 优惠价 </span>
              <span class="discount_money">￥{{ chooseSpecInfo.originalPrice }}</span>
            </div>

          </div>
          <div class="right">
            <span class="pay">{{ data_info.buyQuantity }}人付款
            </span>
            <span class="get">{{ data_info.likedQuantity }}人想要
            </span>
          </div>
        </div>
      </div>
      <div class="limit-text2 black-l mt-24 ft-32">
        {{ data_info.spuTitle }}
      </div>

      <div class="line mt-24" />
      <div style="display: flex;justify-content: space-between;padding:9px 0 8px 0;">
        <span style="color: #656565;">发货</span>
        <div
          style="display: flex;align-items: center;font-size: 12px;"
          class="pr-10"
        >

          <img
            style="width: 18px;height:18px"
            src="../../../assets/share/package.png"
            alt=""
          >
          <span class="ml-16">{{ $filters.shipping_method(data_info.deliveryMethod) }}
            <span v-if="data_info.deliveryMethod=='1'">{{ data_info.freight?(data_info.freight+'元'):'包邮' }} </span>
            <span v-else>{{ data_info.freight?(data_info.freight+'元'):'' }}</span>
          </span>
          <img
            class="ml-16"
            style="width: 16px;height:16px"
            src="../../../assets/share/ask.png"
            alt=""
          >
          <div
            class="ml-16"
            style="background-color: #e5e5e5;width: 1px;height: 12px;"
          />
          <span class="ml-6">{{ data_info.cityName }}</span>
        </div>
      </div>

    </div>

    <div class="split mt-18" />
    <div class="out-div pl-32 pr-32">
      <div
        class="mt-16"
        style="padding: 8px 8px 0 8px;background-color: white;"
      >

        <div
          class="b8  flex ft-24"
          style="background: linear-gradient(270deg, rgba(255,255,255,0) 0%, #EDEEF0 100%);height: 36px;align-items: center;"
        >
          <div class="gray ml-24 flex flex-v-c">
            实名认证   <img
              class="ml-16"
              style="width: 16px;height:16px"
              src="../../../assets/share/ok.png"
              alt=""
            >
          </div>
          <div class="gray ml-24 flex flex-v-c">
            平台认证   <img
              class="ml-16"
              style="width: 16px;height:16px"
              src="../../../assets/share/ok.png"
              alt=""
            >
          </div>

        </div>
        <div style="height: 16px;" />
      </div>

      <div
        class="mt-16 pd-32 bg-white flex flex-bt"
      >
        <span class="black-l ft-28">购买须知</span>

        <span class="right-span">
          查看
          <img
            src="../../../assets/share/rightGray.png"
            alt=""
          >
        </span>

      </div>

      <div class="mt-16" />
      <div class="tab-view-out tabs-view">
        <van-tabs
          line-width="40rpx"
          line-height="4rpx"
          color="#88A3CC"
          active="{{ active }}"
          bind:change="onChange"
        >
          <van-tab title="详情">
            <div class=" ">

              <div class=" pr-32 pl-32 pb-32 bg-white des-pet">
                <div class="gray-l ft-28 mb-20 mt-32">宠物描述</div>
                <div class="desc">
                  <div
                    id="descView"
                    class="gray-l ft-28"
                  >{{ data_info.spuDetail }}
                  </div>

                </div>

              </div>
            </div>
            <div class="mt-16" />
            <div class=" bg-white  pb-16 ">
              <div class="flex ">
                <!--                    <div style="width: 140rpx;">-->
                <!--                        <text class="ml-32 ft-28 black-l">{{filter.formatDateMD(item.created_at)}}</text>-->
                <!--                    </div>-->
                <div
                  style="flex: 1;width: 468px;"
                  class="pr-16 pl-16"
                >
                  <div
                    v-if="info.defaultVideoUrl"
                    class="video pt-16"
                  >
                    <video
                      style="width: 100%;"
                      controls
                      object-fit="cover"
                      :src="info.defaultVideoUrl"
                    />
                  </div>
                  <div class="mt-24 b8 ">

                    <div class="photo-div-one">
                      <div
                        v-for="(item,index) in info.details"
                        :key="index"
                      >
                        <img
                          v-if="index<2"
                          style="width:100%"
                          class="pet-image b8 mb-8"
                          :src="$filters.oss(item.mediaUrl)"
                        >
                      </div>

                    </div>

                  </div>
                </div>
              </div>

            </div>

          </van-tab>

        </van-tabs>
        <img
          data-item="{{info}}"
          class="report-image"
          src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/lyUrZKCDgfEN-Z_hH_UP.png"
        >

      </div>
    </div>
    <div class="split mt-18" />

    <div class="zc-bottom flex flex-v-c">
      <!--      <img-->
      <!--        src="/static/image/pet_chat.png"-->
      <!--        class="btn-chat ml-32"-->
      <!--        alt=""-->
      <!--      >-->
      <div class="btn-chat ml-32">私聊</div>
      <img
        src="/static/image/pet_buy.png"
        class="btn-buy ml-32"
        alt=""
      >
    </div>
  </div>

</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'SharePet',
  props: ['info'],
  setup(props) {
    const $router = useRouter()
    const query = reactive($router.currentRoute.value.query)
    const data_info = ref(props.info)
    const selected = ref(0)
    console.log(data_info, 'data_info')

    const params = { spuId: data_info.value.spuId }
    console.log(params, 'paramsparams')
    let chooseSpecInfo = reactive({})
    if (data_info.value && data_info.value.skus && data_info.value.skus[0].children && data_info.value.skus[0].children.length) {
      if (data_info.value.skuId) {
        // chooseSpecInfo = {
        //   ...(data_info.value && data_info.value.skus && data_info.value && data_info.value.skus[0].children[0])
        // }
        const skus = data_info.value.skus || []
        skus.forEach((m, i) => {
          m.children.forEach((n) => {
            if (data_info.value.skuId == n.skuId) {
              chooseSpecInfo = n
              selected.value = i
            }
          })
        })
        if (!chooseSpecInfo.salePrice) {
          chooseSpecInfo = {
            ...(data_info.value && data_info.value.skus && data_info.value && data_info.value.skus[0].children[0])
          }
        }
      } else {
        chooseSpecInfo = data_info.value.skus[0].children[0]
      }
    } else {
      if (data_info.value.skuId) {
        const skus = data_info.value.skus || []
        skus.forEach((m, i) => {
          if (data_info.value.skuId == m.skuId) {
            chooseSpecInfo = m
            selected.value = i
          }
        })
        if (!chooseSpecInfo.salePrice) {
          chooseSpecInfo = {
            ...(data_info.value && data_info.value.skus.length && data_info.value.skus[0])
          }
        }
      } else {
        chooseSpecInfo = {
          ...(data_info.value && data_info.value.skus.length && data_info.value.skus[0])
        }
      }
    }
    return {
      data_info,
      chooseSpecInfo,
      query,
      selected
    }
  }
})
</script>

<style  lang="scss">
.pet-detail{
  background-color: #FAFAFA;
.pet-img {
  width: 750px;
  height: 750px;
  object-fit: cover;
}
  .shopInfo{
    background: white;
  }
.pic-list{
  margin: 30px 0 32px 0;
  padding-left: 32px;
  display: flex;
  justify-content: flex-start;
  img{
    margin-right: 24px;
    width: 84px;
    height: 84px;
    &.selected{
      border: 1px solid #88A3CC;
    }
  }
}
  .money-text{
    font-size: 24px;
    color: #707070 ;
  }
  .money{
    font-size: 48px;
  }
  .discount_money{
    color: #707070 ;
    font-size: 24px;
    margin-left: 16px;
    text-decoration: line-through;
  }
  .limit-time-view{
    width: 324px;
    height: 134px;
    position:relative;
    background: url("https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1782959624849334272.png") no-repeat center;
    background-size: cover;
    display: flex;
    color:#FFFFFF;
    font-size:36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 68px;
    box-sizing: border-box;
    .count-down{
      margin-top: 12px;
      font-size: 24px;
      .van-count-down{
        color:#FFFFFF;
      }
    }
    img{
      position: relative;
      top: 0;
      right: 0;
      width: 324px;
      height: 134px;
    }
  }
  .limit-time-div{
    width: 100%;
    height: 134px;
    background: linear-gradient(90deg, rgba(157,176,227,0.1) 0%, rgba(165,188,221,0.2) 100%);
    border-radius: 8px;
    border: 2px solid #E7EEF8;
    .left{
      padding-top: 16px;
      text-align: center;
      flex: 1;
      .money{
        margin-right: 4px;
      }
      .stock{
        margin-left: 12px;
        font-size: 22px;
        color: #88A3CC;
        padding: 4px 10px;
        border-radius: 4px;
        border: 2px solid #88A3CC;
      }
      .pay,.get{
        color:#656565;
        font-size: 24px;
      }
    }
  }
  .right-span{
    font-size: 24px;
    color: #9a9a9a;
    img{
      width: 16px;
      height: 20px;
    }
  }
.line-l {
  background-color: #E5E5E5;
  width: 1px;
  height: 24px;
}
.report-image{
  z-index: 99;
  position: absolute;
  right: 0;
  top: 0;
  width: 212px;
  height: 64px;

}
.certified_tex {
  width: 104px;
  height: 32px;
  border: 1px solid #88A3CC;
  opacity: 1;
  border-radius: 4px;

  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 32px;
  color: #88A3CC;
  span-align: center;
}

.btn-chat {
  text-align: center;
  width: 320px;
  height: 72px;
  line-height: 72px;
  font-size: 28px;
  color: #8BA3C7;
  background: #c9d0dd2e;
}

.btn-buy {
  width: 320px;
  height: 72px;
}

.left-span {
  width: 434px;
}

.color-shop {
  border: 1px solid #A39A65;
  color: #A39A65
}
.van-tab{
  justify-content: left;

}
  .van-tabs__line{
    opacity: 0;
  }
  .tabs-view{
    position: relative;
  }
  .zc-bottom{
    z-index:9999;
  }
}
</style>
