import request from '@/utils/requestJava'

export function getShopHome(shopId, query) {
  return request({
    url: `/supplier-server/app-api/supplierShop/homePage/${shopId}/`,
    method: 'get',
    params: query
  })
}

