<template>
  <div class="share-video">
    <div class="mt-0 video-content">
      <video
        id="video"
        :src="data_info.mediaList[0].mediaType=='2'
          ?data_info.mediaList[0].mediaUrl:data_info.mediaList[1].mediaUrl"
        :poster="data_info.mediaList[0].mediaType=='2'
          ?data_info.mediaList[1].mediaUrl:data_info.mediaList[0].mediaUrl"
        @click="playVideo"
      />
      <img
        v-if="controls"
        src="../../../assets/video/play.png"
        alt=""
        @click="playVideo"
      >
    </div>
    <div class="video-bottom pl-32 pr-32">
      <div class="top  flex flex-v-c ">
        <img
          class="user-photo"
          :src="data_info.user.avatar||'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nTkuEN5PhFQOjnRCHox5.jpg'"
          alt=""
        >
        <div class="name">{{ data_info.user.nickname }}</div>
        <div class="attention">
          关注
        </div>
      </div>
      <div class="desc limit-text2">
        {{ data_info.content }}
      </div>
      <div class="time" />
      <div class="oper flex">
        <div class="input">
          <span>快来发表你的评论吧</span>
        </div>
        <div class="img-div">
          <img
            src="../../../assets/video/attention.png"
            alt=""
          >
          <span>{{ data_info.likedQuantity }}</span>
        </div>
        <div class="img-div">
          <img
            src="../../../assets/video/comment.png"
            alt=""
          >
          <span>{{ data_info.reviewedQuantity }}</span>
        </div>
        <div class="img-div">
          <img
            src="../../../assets/video/collect.png"
            alt=""
          >
          <span>{{ data_info.collectedQuantity }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent, ref, onMounted, nextTick } from 'vue'

export default defineComponent({
  name: 'ShareMoment',
  props: ['info'],
  setup(props) {
    const controls = ref(true)
    const data_info = ref(props.info)

    let vide = ref('')
    onMounted(() => {
      vide = document.getElementById('video')
      nextTick(() => {
        vide.addEventListener('ended', function() { // 结束
          controls.value = true
        }, false)
        vide.addEventListener('playing', function() { // 播放中
          controls.value = false
        })
        // proxy.$refs["video"].play()
      })
    })
    const playVideo = () => {
      controls.value = !controls.value
      if (!controls.value) {
        vide.muted = false
        vide.play()
      } else {
        vide.pause()
      }
    }

    return {
      data_info,
      playVideo,
      controls
    }
  }
})
</script>

<style scoped lang="scss">
   .share-video{
       position: absolute;
       top: 0;
       bottom: 0;
       background: black;
       overflow-y: auto;
    .video-content{
        position: relative;

        video{
            width: 100vw;
            height: 100vh;
        }
        img{
            height: 44px;
            width: 44px;
            position: absolute;
            top: calc(50% - 22px);
            left:  calc(50% - 22px);
        }
    }
       .video-bottom{
           color: #ffffff;
           position: absolute;
           bottom: 0;
           left: 0;
           width: 100%;
           padding-bottom: 44px;
           box-sizing: border-box;
           .user-photo{
             width: 84px;
             height: 84px;
             border-radius: 50%;
         }
           .name{
               margin: 0 16px;
           }
           .attention{
               padding: 8px 40px;
               background: #88A3CC ;
               border-radius: 32px;
               font-size: 28px;
               color: #ffffff;
           }
           .desc{
               margin: 16px 0 28px 0;
              font-size: 28px;
           }
           .time{
               padding-bottom: 22px;
               font-size: 24px;
               margin: 28px 0 24px 0;
               border-bottom: 2px solid #ffffff;
           }
           .oper{
               justify-content: space-between;
               align-items: center;
             .input{
                 background: rgba(255,255,255,0.39);
                 padding: 18px 24px;
                 border-radius: 38px;
                 /*opacity: 0.6;*/
             }
               .img-div{
                   span{
                       font-size: 24px;
                   }
               }
               img{
                   width: 48px;
                   height: 48px;
               }
           }
       }
   }

</style>
