<template>
  <div class="pl-32 pr-32">
    <div class="ft-40 title mt-30">{{ data_info.title }}</div>
    <div v-html="data_info.content" />

  </div>

</template>

<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'ShareArticle',
  props: ['info'],
  setup(props) {
    const data_info = ref(props.info)
    return {
      data_info
    }
  }
})
</script>

<style scoped>
.title {
  font-weight: 500;
}
</style>
