<template>
  <div class="ml-16 mr-8 bg-white b24 pb-16" catchtap="openDetail">
    <img class="b24 img-content" mode="widthFix" :src="$filters.goodsThumb(info)"/>
    <div class="pl-16 pr-16 mt-16">
      <span class="limit-text2">{{ info.content }}</span>
    </div>
    <div class="mt-8 pl-8 flex">
      <template v-for="(item,index) in info.topics_info" :key="index">
        <span class="topic-label" data-item="{{item}}" catchtap="openTopic"># {{ item.name }}</span>
      </template>
    </div>
    <div class="pl-16 mt-16 flex flex-bt flex-v-c">
      <div class="flex flex-v-c">
        <img :src="$filters.userAvatar(user_info)" class="size-44" style="border-radius: 50%;"
              />
        <text class="ml-8 ft-20" style="color: #b2b2b2;">{{ $filters.shopNickname(user_info) }}</text>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from 'vue'
export default defineComponent({
  name: "MomentItem",
  props: ['info','user_info'],
  setup(props) {
    let data_info = reactive(props.info)


    return {
      data_info
    }
  }
})
</script>

<style scoped>

.img-content {
  width: 350px;
}

</style>
