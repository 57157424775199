<template>
  <div>
    <div class="mt-32 flex flex-v-c pl-32 pr-32 pb-32 flex-bt border-top">
      <div class="flex flex-v-c">
        <img
          class="size-44 b-50p"
          :src="data_info.user.avatar"
          alt=""
        >
        <span class="ml-6 ft-28 black-l">  {{ data_info.user.nickname }}</span>
      </div>
      <div
        class="pl-28 pr-28 pt-4 pb-4 ft-24"
        style="background: rgba(136,163,204,0.2);border-radius: 11px;"
      >
        <span style="color: #88A3CC">关注</span>
      </div>
    </div>
    <div class="mt-20 pl-32 pr-32 ">
      <div class="title">
        {{ data_info.content }}
      </div>
      <div class="desc limit-text2">
        {{ data_info.description }}
      </div>
      <div class="img-div">
        <img
          v-for="(m,i) in data_info.pictureUrls"
          :key="i+'s'"
          :src="m"
          alt=""
        >
      </div>
      <div class="time-div">
        {{ data_info.createDateTime }}
      </div>
      <div class="list-out">
        <div class="list-title">
          <span class="title-left">共{{ data_info.reviewedQuantity }}条回答</span>
        </div>
        <div
          v-for="(m,i) in data_info.list"
          :key="i+'k'"
          class="list flex"
        >
          <div class="left">
            <img
              :src="m.user.avatar"
              class="user-photo"
              alt=""
            >
          </div>
          <div class="right">
            <div class="name">{{ m.user.nickname }}</div>
            <div class="list-content">{{ m.content }}</div>
            <div class="time">{{ m.createDateTime }}</div>
            <div
              v-if="m.reviewedQuantity>0"
              class="sub-answer"
            >展开{{ m.reviewedQuantity }}条回复<van-icon
              name="arrow-down"
              color="#9a9a9a"
            /></div>
            <img
              class="attention-img"
              src="../../../assets/video/attention_gray.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mt-32 pl-32 pr-32">
      <div class="line" />
    </div>
    <div class="zc-bottom flex flex-bt flex-v-t">
      <van-field
        disabled
        class="ml-32 ft-24 pt-16 sub-field"
        placeholder="快来写问答吧～"
      />
      <div class="ml-32 flex flex-v-b ft-20 gray-l mr-32">
        <img
          class="size-48"
          src="/static/image/love.png"
        >
        <span>{{ data_info.praise_num }}</span>
        <img
          class="size-48 ml-32"
          src="/static/image/store.png"
        >
        <span>{{ data_info.collect_num }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ShareQAnswer',
  props: ['info'],
  setup(props) {
    const data_info = ref(props.info)
    return {
      data_info
    }
  }
})
</script>

<style scoped lang="scss">
.border-top{
  padding-top:34px;
  border-top: 1px solid #f1f1f1;

}
.desc{
   color: #000000;
   font-size: 28px;
   margin-bottom: 48px;
}
.title{
  font-size: 36px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 16px;
}
.img-div{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  img{
    width: 222px;
    height: 222px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
}
.time-div{
  color: #B2B2B2;
  font-size: 12px;
  margin-top: 48px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f1f1f1;
}
.list-out{
  margin-top: 32px;
  padding-bottom: 60px;
  .list-title{
    margin-bottom: 26px;
  }
  .title-left{
    color: #333333;
    font-size: 28px;
    font-weight: bold;
  }
  .list{
    margin-bottom: 42px;
    .user-photo{
      width: 44px;
      height: 44px;
      margin-right: 12px;
      border-radius: 50%;
    }
    .name{
      color: #B2B2B2;
      font-size: 24px;
    }
    .right{
      width: calc(100% - 60px);
      position:relative ;
      .time{
        color: #B2B2B2;
        font-size: 20px;
      }
      .list-content{
        color: #333333;
        font-size: 28px;
        margin: 4px 0;
      }
      .attention-img{
        position: absolute;
        right: 16px;
        top: 0;
        width: 32px;
        height: 32px;
      }
      .sub-answer{
        color: #B2B2B2;
        font-size: 24px;
      }
    }

  }
}
.sub-field {
  background-color: #f2f2f2;flex: 1;border-radius: 28px;height: 60px;
  display: flex;
  align-items: center;
}

</style>
