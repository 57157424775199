<template>

  <div class="flex flex-bt flex-v-c pl-32 pr-32 bg-w content">
    <img
      src="/static/image/logo.png"
      class="size-88"
    >
    <div
      class="flex flex-col flex-v-t ml-16"
      style="flex: 1;"
    >
      <span class="ft-32 black-l">集宠区</span>
      <span class="ft-24 gray-l">全品类宠物交易平台</span>
    </div>
    <div
      class="flex flex-v-c flex-h-c ft-28 btn-open"
      @click="openShare"
    >
      打开APP
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'DownTip',
  props: ['info'],
  emits: ['openShare'],
  setup(props, { emit }) {
    const show = ref(false)
    const openShare = () => {
      emit('openShare')
    }
    return {
      show,
      openShare
    }
  }
})
</script>

<style scoped>
.content {
  height: 120px;
}

.btn-open {
  color: white;
  border-radius: 36px;
  width: 176px;
  height: 72px;
  background-color: #88A3CC
}
</style>
