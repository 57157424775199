<template>
  <div class="brand-div">
    <div class="top ">
      <div class="shop-info flex flex-bt flex-v-c">
        <div class="left inline-flex flex-v-c ">
          <img
            :src="data_info.shopLogo"
            alt=""
            class="mr-24"
          >
          <div>
            <div class="shop-name mb-16 ft-32 ">{{ data_info.shopName }}</div>
            <div>
              <span v-if="data_info.spuTotal">
                {{ data_info.spuTotal }}万+款商品
              </span>
              <span v-if="data_info.newSpuTotal">
                上新{{ data_info.newSpuTotal }}件
              </span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="sub">
            <img
              src="../../../assets/share/sub.png"
              alt=""
              class="mr-20"
            >
          </div>
        </div>
      </div>
      <div
        v-if="data_info.intro"
        ref="intro"
        class="intro limit-text mt-32"
        :class="[showMore?'':'limit-text-most',showLimit&&showMore?'hasPadding':'']"
        @click="clickIntro"
      >
        {{ data_info.intro }} <span
          v-if="showLimit"
          class="up-down"
          :class="[showLimit&&showMore?'down':'up']"
        />
      </div>
      <div class="tag flex flex-v-c mt-32">
        <div
          v-for="(m,i) in tagList"
          :key="i"
          class="tag-inner inline-flex"
          :class="[selectedIndex==i?'selected':'']"
          @click="chooseTag(i)"
        >
          {{ m.name }} <img
            v-if="i==1"
            :src="price_img"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="list-div">
      <div
        v-for="(m,i) in data_info.spus"
        :key="i"
        class="list"
      >
        <img
          :src="m.defaultPictureUrl"
          alt=""
        >
        <div class="title limit-text2">
          {{ m.spuTitle }}
        </div>
        <div class="price flex flex-bt flex-v-c">
          <span class="price-span">￥{{ m.salePrice }}</span>
          <span>{{ m.buyQuantity }}人付款</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, nextTick, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'ShareTopic',
  props: ['info'],
  setup(props) {
    const data_info = reactive(props.info)

    const { proxy } = getCurrentInstance()
    const router = useRouter()

    const tagList = reactive([
      {
        name: '销量'
      },
      {
        name: '价格'
      },
      {
        name: '新品'
      }
    ])
    const goodsList = reactive([
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' }
    ])
    const iconList = [require('../../../assets/share/upDefault.png'),
      require('../../../assets/share/downTwo.png'),
      require('../../../assets/share/upTwo.png')

    ]
    const price_img = ref(iconList[0])
    const data = reactive({
      userInfo: {
        shopName: '柯基小铺子',
        shopPcount: '5',
        shopNewcount: '68',
        intro: '品牌故事品牌故事 品牌故事品牌故事 品牌故事品牌故事 品牌故事品牌故事品牌故事品牌故事 品牌故事品牌故事品牌故事品牌故事 品牌故事品牌故事品牌故事品牌故事 品牌故事品牌故事。',
        avatarUrl: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nTkuEN5PhFQOjnRCHox5.jpg'
      }
    })
    const selectedIndex = ref(0)
    const chooseTag = (i) => {
      selectedIndex.value = i
      if (i == 1) {
        price_img.value = price_img.value == iconList[2] ? iconList[1] : iconList[2]
      } else {
        price_img.value = iconList[0]
      }
    }
    const showLimit = ref(false)
    const showMore = ref(true)
    const clickIntro = () => {
      showMore.value = !showMore.value
    }
    onMounted(() => {
      const introDom = proxy.$refs.intro

      if (introDom.scrollHeight > introDom.offsetHeight) {
        showLimit.value = true
      }
    })
    return {
      data_info,
      data,
      chooseTag,
      price_img,
      goodsList,
      tagList,
      selectedIndex,
      showLimit,
      clickIntro,
      showMore
    }
  }
})
</script>

<style scoped lang="scss">
div{box-sizing: border-box}
.brand-div{
  background:#F5F5F5;
  position: relative;
  width: 100%;

  .top{
    padding:20px 32px 24px 32px;
    background:white;
    .left{
      img{
        width:  120px;
        height:120px
      }
      .shop-name{
        font-weight: bold;
      }

    }
    .right{
      img{
        width:128px;
        height:50px;
      }
    }
    .tag{
      .tag-inner{
        font-size: 28px;
         margin-right:108px;
        &.selected{
          color:#88A3CC ;
        }
        img{
          width:17px;
          height:26px;
          margin-left:6px;
          position: relative;
          top: 8px;
        }
      }
    }
    .intro{
      position: relative;
      &.hasPadding{
        padding-right: 30px;
      }
      .up-down{
        display: inline-block;
        width: 17px;
        height: 11px;
        background: url("../../../assets/share/down.png") no-repeat;
        background-size: 100%;
        position: absolute;
        right: 10px;
        top: 14px;
        &.up{
          background: url("../../../assets/share/up.png") no-repeat;
          background-size: 100%;
          position:relative;
          top: -4px;
        }
      }
    }
  }
  .list-div{
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    .list{
      background: #fcfcfc;
      margin-bottom: 16px;
      width:350px;
      .title{
        padding: 16px;
        color: #333333;
        font-size: 28px;
        height:86px;
      }
      .price{
        padding: 16px;
        font-size: 24px;
        color:#676767;

        .price-span{
          color: #000000;
          font-size: 36px;
        }
      }
      img{
        width:350px;
        background: white;
        height: 350px;
      }
    }
  }
}

</style>
