<template>
  <div class="share_store">

    <img
      class="pet-img"
      :src="chooseSpecInfo.skuPictureUrls[0]"
      alt=""
    >
    <div class="pic-list">
      <img
        v-for="(m,i) in data_info.skus"
        :key="i"
        alt=""
        :src="m.specificationPictureUrl"
        :class="[selected==i?'selected':'']"
      >
    </div>
    <div class="free pr-32">
      <div class="free-top flex">
        <div
          v-if="data_info.couponInfo&&data_info.couponInfo.couponInfoText"
          class="mr-16 card coupon-tag"
        >
          <span>{{ info.couponInfo&&info.couponInfo.couponInfoText }}</span>
          <img src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nCYW8dKmfKlQG1iIOj2U.png">

        </div>
        <img
          src="/static/image/free_mail.png"
          alt=""
        >
      </div>
      <div class="">
        <div class="border-div">
          <div
            v-if="chooseSpecInfo.activity&&chooseSpecInfo.activity.activityPriceType==1"
            class="flex flex-bt pt-10 pb-10"
          >
            <!--            新人活动-->
            <div class="left">
              <div>

                <span
                  class="money-text"
                  style="color: #8BA3C7"
                >￥<span class="money">
                  {{ chooseSpecInfo.activity?chooseSpecInfo.activity.activityPrice:chooseSpecInfo.salePrice=='0'?'--':chooseSpecInfo.salePrice }}
                </span> {{ chooseSpecInfo.activity?'新人价':'优惠价' }}  </span>
                <span class="discount_money">￥{{ chooseSpecInfo.originalPrice }}</span>
              </div>

            </div>
            <div class="right">
              <span class="pay">{{ data_info.buyQuantity }}人付款
              </span>
              <span class="get">{{ data_info.likedQuantity }}人想要
              </span>
            </div>
          </div>
          <div
            v-else-if="chooseSpecInfo.activity&&chooseSpecInfo.activity.activityPriceType==2"
          >
            <!--            限时秒杀  未结束-->
            <div
              v-if="chooseSpecInfo.activity&&chooseSpecInfo.activity.countdown"
              class="flex   limit-time-div"
            >
              <div class="left">
                <div class=" flex flex-h-c flex-v-c">

                  <span
                    class="money-text"
                    style="color: #8BA3C7"
                  >￥<span class="money">
                    {{ chooseSpecInfo.activity?chooseSpecInfo.activity.activityPrice:chooseSpecInfo.salePrice=='0'?'--':chooseSpecInfo.salePrice }}
                  </span> {{ chooseSpecInfo.activity?'秒杀价':'优惠价' }}  </span>
                  <span class="stock">仅剩{{ chooseSpecInfo.currentStockQuantity }}件</span>
                </div>
                <div class=" flex flex-v-c mt-8 pl-20">
                  <span class="pay">{{ data_info.buyQuantity }}+人付款
                  </span>
                  <span class="get ml-24">{{ data_info.likedQuantity }}人想要
                  </span>
                </div>

              </div>
              <div class="limit-time-view">
                <!--                <img-->
                <!--                  src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1782955162374119424.png"-->
                <!--                  alt=""-->
                <!--                >-->
                <div>限时秒杀</div>
                <div class="count-down flex flex-v-c">
                  <van-count-down

                    v-if="chooseSpecInfo.activity&&chooseSpecInfo.activity.countdown"
                    :time="chooseSpecInfo.activity.countdown*1000"
                    format="HH:mm:ss"
                  />后结束
                </div>

              </div>
            </div>
            <div
              v-else
              class="flex flex-bt pt-10 pb-10"
            >
              <div class="left">
                <div>

                  <span
                    class="money-text"
                    style="color: #8BA3C7"
                  >￥<span class="money">
                    {{ chooseSpecInfo.salePrice=='0'?'--':chooseSpecInfo.salePrice }}
                  </span> 优惠价 </span>
                  <span class="discount_money">￥{{ chooseSpecInfo.originalPrice }}</span>
                </div>

              </div>
              <div class="right">
                <span class="pay">{{ data_info.buyQuantity }}人付款
                </span>
                <span class="get">{{ data_info.likedQuantity }}人想要
                </span>
              </div>
            </div>
          </div>
          <div
            v-else
          >
            <!--         常规商品展示-->
            <div

              class="flex flex-bt pt-10 pb-10"
            >
              <div class="left">
                <div>

                  <span
                    class="money-text"
                    style="color: #8BA3C7"
                  >￥<span class="money">
                    {{ chooseSpecInfo.salePrice=='0'?'--':chooseSpecInfo.salePrice }}
                  </span> 优惠价 </span>
                  <span class="discount_money">￥{{ chooseSpecInfo.originalPrice }}</span>
                </div>

              </div>
              <div class="right">
                <span class="pay">{{ data_info.buyQuantity }}人付款
                </span>
                <span class="get">{{ data_info.likedQuantity }}人想要
                </span>
              </div>
            </div>
          </div>
          <div class="name limit-text2">
            {{ data_info.spuTitle }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!data_info.checkResult"
      class="card-view"
    >
      <view
        class="image-out"
      >
        <img src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/4UA7TyxjhNxLXTKMrF5G.png">
        <span>点击查看会员卡折扣详情</span>
      </view>
    </div>
    <div
      v-if="data_info.shippingState||info.afterSaleState"
      class="free pr-32"
    >
      <div class="border-div service-view flex">
        <div
          v-if="data_info.shippingState"
          class="service"
        >
          <span>{{ data_info.shippingStateText }}</span><img src="../../../assets/share/service.png">
        </div>
        <div
          v-if="data_info.afterSaleState"
          class="service"
        >
          <span>{{ data_info.afterSaleStateText }}</span><img src="../../../assets/share/service.png">
        </div>
      </div>
    </div>
    <div class="like-div">
      <div class="title pl-32">相关商品</div>
      <div class="like-list flex">
        <div
          v-for="(m,i) in like_list"
          :key="i+'oo'"
          class="list-div"
        >
          <img
            :src="m.defaultPictureUrl"
            alt=""
          >
          <div class="name limit-text2">
            {{ m.spuTitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="evaluate">
      <span class="">商品评价（{{ data_info.comment_num?data_info.comment_num:0 }}）</span>
      <img
        src="/static/image/right.png"
        alt=""
      >
    </div>
    <div class="split mt-18" />

    <div class="zc-bottom">
      <div class="in-flex">
        <span>
          <img
            src="/static/image/store_plant.png"
            class="btn-buy"
            alt=""
          >
          <div>种草</div>
        </span>
        <span>
          <img
            src="/static/image/store_chat.png"
            class="btn-buy"
            alt=""
          >
          <div>私聊</div>
        </span>
        <span>
          <img
            src="/static/image/store_get.png"
            class="btn-buy"
            alt=""
          >
          <div>想要</div>
        </span>
      </div>
      <img
        src="/static/image/pet_buy.png"
        class="btn-buy ml-32"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
import { comGoodsLikelist } from '@/api/share'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'SharePet',
  props: ['info'],
  setup(props) {
    const $router = useRouter()
    const query = reactive($router.currentRoute.value.query)
    const data_info = ref(props.info)
    const selected = ref(0)
    console.log(data_info, 'data_info')
    const like_list = ref([])
    const params = { spuId: data_info.value.spuId }
    console.log(params, 'paramsparams')
    let chooseSpecInfo = reactive({})
    console.log(data_info.value)
    console.log('58858585885858')
    if (data_info.value && data_info.value.skus && data_info.value.skus[0].children && data_info.value.skus[0].children.length) {
      if (data_info.value.skuId) {
        // chooseSpecInfo = {
        //   ...(data_info.value && data_info.value.skus && data_info.value && data_info.value.skus[0].children[0])
        // }
        const skus = data_info.value.skus || []
        skus.forEach((m, i) => {
          m.children.forEach((n) => {
            if (data_info.value.skuId == n.skuId) {
              chooseSpecInfo = n
              selected.value = i
            }
          })
        })
        if (!chooseSpecInfo.salePrice) {
          chooseSpecInfo = {
            ...(data_info.value && data_info.value.skus && data_info.value && data_info.value.skus[0].children[0])
          }
        }
      } else {
        chooseSpecInfo = data_info.value.skus[0].children[0]
      }
    } else {
      if (data_info.value.skuId) {
        const skus = data_info.value.skus || []
        skus.forEach((m, i) => {
          if (data_info.value.skuId == m.skuId) {
            chooseSpecInfo = m
            selected.value = i
          }
        })
        if (!chooseSpecInfo.salePrice) {
          chooseSpecInfo = {
            ...(data_info.value && data_info.value.skus.length && data_info.value.skus[0])
          }
        }
      } else {
        chooseSpecInfo = {
          ...(data_info.value && data_info.value.skus.length && data_info.value.skus[0])
        }
      }
    }
    console.log(chooseSpecInfo, 'chooseSpecInfochooseSpecInfo')
    comGoodsLikelist(params).then(res => {
      like_list.value = res.data.length > 6 ? res.data.splice(0, 6) : res.data
    })
    return {
      data_info,
      chooseSpecInfo,
      like_list,
      selected,
      query
    }
  }
})
</script>

<style lang="scss">
.share_store{
  padding-bottom: 120px;
  background:  rgba(250,250,250,0.39);
  .pic-list{
    margin: 30px 0 32px 0;
    padding-left: 32px;
    display: flex;
    justify-content: flex-start;
    img{
      margin-right: 24px;
      width: 84px;
      height: 84px;
      &.selected{
        border: 1px solid #88A3CC;
      }
    }
  }
  .free{
    padding-left: 32px;
    padding-top: 18px;
    padding-bottom: 32px;
    background: rgba(255,255,255,1);
    img{
      width: 72px;
      height: 36px;
    }
  }

  .free-top{
    padding-bottom: 8px;
    position: relative;
    .card{
      position: relative;
      width:118px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      span{
        color: #88A3CC;
        font-size: 22px;
        position: relative;
        z-index: 8;
      }
      img{
        position: absolute;
        left: 0;
        top: 0;
        width:118px;
        height: 36px;
      }
      &.coupon-tag{
        width: inherit!important;
        text-align: center;
        padding: 0 10px;
        img{
          width: 100%;
        }
      }
    }
  }

  .border-div{
    margin-top: 10px;
    padding-top: 16px;
    border-top: 1px solid #e2e2e2;
    &.service-view{
      background: #F7F8FA;
      font-size: 24px;
      align-items: center;
      //margin: 20rpx 0 0 0;
      padding: 20px 24px 18px 24px;
      border:none;
      .service{
        margin-right: 32px;
        display: inline-flex;
        align-items: center;
      }
      img{
        width: 24px;
        height: 24px;
        margin-left: 16px;
      }
    }
    .money-text{
      font-size: 24px;
      color: #707070 ;
    }
    .money{
      font-size: 48px;
    }
    .discount_money{
      color: #707070 ;
      font-size: 24px;
      margin-left: 16px;
      text-decoration: line-through;
    }
    .right{
      .pay,.get{
        background: rgba(247,248,250,0.39);
        display: inline-block;
        opacity: 1;
        border-radius: 4px;
        color: #656565;
        font-size: 24px;
        height: 52px;
        line-height: 52px;
        padding: 0 16px;
      }
      .pay{
        margin-right: 16px;
      }
    }

    .name{
      margin-top: 32px;
      color: #333333 ;
      font-size: 24px;
    }
  }
  .limit-time-view{
    width: 324px;
    height: 134px;
    position:relative;
    background: url("https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1782959624849334272.png") no-repeat center;
    background-size: cover;
    display: flex;
    color:#FFFFFF;
    font-size:36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 68px;
    box-sizing: border-box;
    .count-down{
      margin-top: 12px;
      font-size: 24px;
      .van-count-down{
        color:#FFFFFF;
      }
    }
    img{
      position: relative;
      top: 0;
      right: 0;
      width: 324px;
      height: 134px;
    }
  }
  .limit-time-div{
    width: 100%;
    height: 134px;
    background: linear-gradient(90deg, rgba(157,176,227,0.1) 0%, rgba(165,188,221,0.2) 100%);
    border-radius: 8px;
    border: 2px solid #E7EEF8;
    .left{
      padding-top: 16px;
      text-align: center;
      flex: 1;
      .money{
        margin-right: 4px;
      }
      .stock{
        margin-left: 12px;
        font-size: 22px;
        color: #88A3CC;
        padding: 4px 10px;
        border-radius: 4px;
        border: 2px solid #88A3CC;
      }
      .pay,.get{
        color:#656565;
        font-size: 24px;
      }
    }
  }
  .like-div{
    background: #ffffff;
    margin-top: 16px;
    padding: 16px;
    .title{
      color: #333333;
      font-size: 32px;
      font-weight: 500;
    }
    .like-list{
      margin-top: 25px;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
      .list-div{
        width: 208px;
        text-align: center;
      }
      .name{
        font-size: 24px;
        color: #333333;
      }
      img{
        width: 136px;
        height: 136px;
      }
    }
  }
  .evaluate{
    padding: 20px 32px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    align-items: center;
    span{
      color: #333333;
      font-size: 32px;
    }
    img{
      width: 12px;
      height: 20px;
    }
  }
  .pet-img {
    width: 750px;
    height: 750px;
    object-fit: cover;
  }

  .btn-chat {
    width: 196px;
    height: 72px;
  }

  .btn-buy {
    width: 378px;
    height: 72px;
  }

  .zc-bottom{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    left: 0;
    box-sizing: border-box;
    align-items: center;
    z-index:9999;
  }
  .in-flex{
    flex: 1;
    display: inline-flex;
    justify-content: space-around;
    flex-direction: row;
    span{
      display: inline-flex;
      flex-direction: column;
      /*flex-wrap: wrap;*/
      align-items: center;
      div{
        display: inline-block;
      }
    }
    img{
      width: 44px;
      height: 44px;
    }
  }
  .card-view{
    width: 100%;
    height: 84px;
    box-sizing: border-box;
    .image-out{
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 84px;
      padding-left: 44px;
      box-sizing: border-box;
      img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 84px;
      }
      span{
        position: relative;
        z-index: 9;
        font-size: 32px;
        font-weight: bold;
        color: #84A0C7;
      }
    }
  }
}
</style>
