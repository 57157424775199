export function getPhoneBrand() {
    var userAgent = navigator.userAgent.toLowerCase()
    var u = navigator.userAgent

    //heytap  OPPO   xiaomi  小米  huawei 华为
    if (userAgent.match(/MicroMessenger/i)) {
        return 'wechat'//微信
    } else if (userAgent.match(/qq/i)) {
        if(u.indexOf('QQTheme') > -1) {
            return 'qq' //qq
        }else{
            return 'qqbrowse' //qqbrowse
        }
    } else if (userAgent.match(/(iPhone|iPod|iPad)/i)) {
        return 'ios' //苹果
    } else if (userAgent.match(/heytap/i)) {
        return 'heytap' //OPPO
    } else if (userAgent.match(/xiaomi/i)) {
        return 'xiaomi'   //小米
    } else if (userAgent.match(/huawei/i)) {
        return 'huawei'   //华为
    } else if (userAgent.match(/vivo/i)) {
        return 'vivo'   //vivo
    }
    return ''
}

export const isIos = () => {
    const u = navigator.userAgent;
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
}

export const isAndroid = () => {
    const u = navigator.userAgent;
    return u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android终端或者uc浏览器
}

export const isWx = () => {
    var userAgent = navigator.userAgent.toLowerCase()
    return userAgent.match(/MicroMessenger/i)
}


export const isWeiBo = () => {
    var userAgent = navigator.userAgent.toLowerCase()
    return userAgent.match(/weibo/i)
}
