<template>
  <router-view

    v-slot="{ Component,route }"
  >

    <keep-alive>
      <component
        :is="Component"
        v-if="route.meta.keepAlive"
        :key="route.fullPath"
      />
    </keep-alive>
    <component
      :is="Component"
      v-if="!route.meta.keepAlive"
      :key="route.fullPath"
    />

  </router-view>
<!--  <router-view :key="$route.params" />-->
</template>

<script setup>
</script>
<style>
#app {
  font-family: PingFang SC Avenir, Helvetica, Arial, sans-serif , "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
::-webkit-scrollbar{width:6px;height:6px;

  background-color: #f1f1f1;

}

::-webkit-scrollbar-thumb{background-color:#c1c1c1;border-radius:10px;}

::-webkit-scrollbar-track{background-color:transparent; }

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
