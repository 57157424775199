import dateFormat from 'dateformat'

export var oss = function(value, size = 650) {
  if (value.indexOf('.mp4') != -1) { // jpg格式处理
    return value
  }

  if (value.indexOf('https://gpets.oss') == 0 && value.indexOf('?x-oss-process') == -1) {
    return value + '?x-oss-process=image/resize,w_' + size
  }

  return value
}

export var isShop = function(user_info) {
  return user_info && user_info.merchant_info && user_info.merchant_info.id > 0
}
export var shopNickname = function(user_info) {
  if (!user_info) return ''
  if (isShop(user_info)) {
    return user_info.merchant_info.merchant_name
  }
  return user_info.nickname
}

export var shopAddress = function(user_info) {
  if (!user_info) return ''
  if (isShop(user_info)) {
    return user_info.merchant_info.address_info.detail_address
  }
  return user_info.region
}
export var shopLicense = function(user_info) {
  if (isShop(user_info)) {
    return user_info.merchant_info.license_url
  }
  return ''
}

export var userAvatar = function(user_info) {
  return user_info && user_info.avatar && user_info.avatar.length > 0 ? oss(user_info.avatar, 100) : '/static/image/avatar.jpg'
}

export var goodsThumb = function(item) {
  if (!item || !item.photos) {
    return ''
  }
  if (item.photos.length > 0) {
    return oss(item.photos[0], 500)
  }
  if (item.video.length > 0) {
    return item.video + '?x-oss-process=video/snapshot,t_0,f_jpg'
  }
}

function formatDate(time) {
  if (time === null || time === '' || typeof (time) === undefined) {
    return ''
  }
  return dateFormat(time, 'yyyy-mm-dd HH:MM:ss')
}

const filterNullOrEmpty = function(val) {
  if (val === null || val === '' || typeof (val) === undefined) {
    return ''
  } else {
    return val
  }
}

const deduplication = function(list) {
  if (!list || !list.length) {
    return []
  }

  const map = new Map()
  const qc = list.filter(key => !map.has(key.name) && map.set(key.name, 1))
  return qc
}

const deduplicationName = function(list) {
  if (!list || !list.length) {
    return ''
  }
  const map = new Map()
  let name_str = ''
  const qc = list.filter(key => !map.has(key.name) && map.set(key.name, 1))
  for (let i = 0; i < qc.length; i++) {
    name_str += (qc[i].name + '; ')
  }
  return name_str
}

const genderFilter = function(val) {
  if (val === 1 || val === '1') return '男'
  if (val === 2 || val === '2') return '女'
  if (val === 3 || val === '3') return '保密'
  return ''
}

const shippingFilter = function(val) {
  if (val === 1 || val === '1') return '同城快送'
  if (val === 2 || val === '2') return '物流配送'
  if (val === 3 || val === '3') return '自提'
  return ''
}

const certificationFilter = function(val) {
  if (val && val.certification_type && val.certification_type === 2) {
    if (val.merchant_info.server_type === 1) {
      return '繁育商家'
    } else {
      return '服务商家'
    }
  } else {
    return '个人商家'
  }
}

const certificationType = function(val) {
  if (val && val.certification_type && val.certification_type === 2) {
    return '商家认证'
  } else {
    return '个人商家'
  }
}

const shopNameFilter = function(val) {
  if (val && val.certification_type && val.certification_type === 2) {
    return val.merchant_info.merchant_name
  } else {
    return val.name
  }
}

const petStatusFilter = function(val) {
  if (val === 1 || val === '1') return '待审核'
  if (val === 2 || val === '2') return '违规下架'
  if (val === 3 || val === '3') return '已上架'
  if (val === 4 || val === '4') return '已下架'
  if (val === 5 || val === '5') return '交易中'
  if (val === 6 || val === '6') return '已售出'
  return ''
}

const orderStatusFilter = function(val) {
  if (val === 1 || val === '1') return '待付款'
  if (val === 2 || val === '2') return '待接单'
  if (val === 3 || val === '3') return '待发货'
  if (val === 4 || val === '4') return '待收货'
  if (val === 5 || val === '5') return '已完成'
  if (val === 6 || val === '6') return '退款中'
  if (val === 7 || val === '7') return '已关闭'
  if (val === 8 || val === '8') return '售后中'
  if (val === 99 || val === '99') return '异常'
  return ''
}

const alipayOrderStatusFilter = function(val) {
  if (val === 'TRADE_SUCCESS') return '交易成功, 未退款'
  if (val === 'TRADE_CLOSED') return '交易关闭, 已全额退款'
  if (val === 'TRADE_FINISHED') return '交易成功'
}

const imageResize = function(val, w = 300) {
  return val + '?x-oss-process=image/resize,w_' + w
}

const versionChannleFilter = function(val) {
  if (parseInt(val) === 1) return 'iOS'
  if (parseInt(val) === 2) return '安卓普通'
  if (parseInt(val) === 3) return '小米'
  if (parseInt(val) === 4) return 'oppo'
  if (parseInt(val) === 5) return '华为'
  if (parseInt(val) === 6) return '应用宝'
  if (parseInt(val) === 7) return '其他'
  return ''
}

const dewormTypeFilter = function(val) {
  if (parseInt(val) === 0) return '疫苗'
  if (parseInt(val) === 1) return '体内驱虫'
  if (parseInt(val) === 2) return '体外驱虫'
}
const shipping_method = function(type) {
  if (parseInt(type) == '1') return '快递'
  if (parseInt(type) == '2') return '空运'
  if (parseInt(type) == '3') return '汽运'
  if (parseInt(type) == '4') return '顺风车'
  if (parseInt(type) == '5') return '自提'
}
const secretName = function(value) {
  if (!value || value.length < 3) {
    return value
  }
  return name = value.slice(0, 1) + '*' + value.slice(value.length - 1, value.length)
}
export {
  certificationType,
  formatDate,
  filterNullOrEmpty,
  deduplication,
  deduplicationName,
  genderFilter,
  shippingFilter,
  certificationFilter,
  petStatusFilter,
  shopNameFilter,
  imageResize,
  orderStatusFilter,
  alipayOrderStatusFilter,
  versionChannleFilter,
  dewormTypeFilter,
  shipping_method,
  secretName
}
