import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/common.css'
import * as filters from './utils/filter.js'

import vant from 'vant'
import 'vant/lib/index.css'
import touch from 'vue3-hand-mobile'

const app = createApp(App)

import { Image as VanImage } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import { Icon } from 'vant'
import { Field } from 'vant'
import { Popup } from 'vant'
import { Overlay } from 'vant'
import { lazyPlugin } from './directive'
app.use(store).use(router).use(Swipe).use(SwipeItem).use(VanImage)
  .use(Icon).use(Field).use(Popup).use(Overlay).use(vant).use(touch).use(lazyPlugin)
app.config.globalProperties.$filters = filters

app.mount('#app')
