const hasSession = () => {
  if (sessionStorage.getItem('headerColor') != 'undefined' && sessionStorage.getItem('headerColor') != undefined && sessionStorage.getItem('headerColor')) {
    return true
  } else {
    return false
  }
}
const state = {

  headerColor: hasSession() ? JSON.parse(sessionStorage.getItem('headerColor')) : {
    colorIndex: 'white',
    colorActive: 'white',
    colorMerchant: 'white',
    colorAbout: 'white',
    dropColor: 'black'
  }

}

const mutations = {

  SETCOLOR: (state, headerColor) => {

    if (!headerColor) {
      return
    }
    sessionStorage.setItem('headerColor', JSON.stringify(headerColor))
    state.headerColor = headerColor
  }
}

const actions = {
  setHeaderColor({ commit }, headerColor) {
    commit('SETCOLOR', headerColor)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
