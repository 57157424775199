<template>
  <div class="mt-0">
    <van-swipe
      v-if="data_info && data_info.mediaList.length >0"
      :autoplay="3000"
      height="385"
    >
      <van-swipe-item
        v-for="(item,index) in data_info.mediaList"
        :key="index"
      >
        <img
          :src="$filters.oss(item.mediaUrl)"
          class="mine-img"
        >
        <van-image
          fit="cover"
          class="mine-img"
          :src="$filters.oss(item.mediaUrl)"
        />
      </van-swipe-item>
      <template #indicator="{ active, total }">
        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
      </template>

    </van-swipe>
  </div>
  <div
    class="mt-32"
    style="text-align: center"
  >
    <span class="gray-l">打开集宠区APP<van-icon
      size="14"
      name="arrow"
    /></span>
  </div>

  <div class="mt-32 flex flex-v-c pl-32 pr-32 flex-bt">
    <div class="flex flex-v-c">
      <img
        class="size-44 b-50p"
        :src="data_info.user.avatar"
        alt=""
      >
      <span class="ml-6 ft-28 black-l">  {{ data_info.user.nickname }}</span>
    </div>

    <div
      class="pl-28 pr-28 pt-4 pb-4 ft-24"
      style="background: rgba(136,163,204,0.2);border-radius: 11px;"
    >
      <span style="color: #88A3CC">关注</span>
    </div>

  </div>
  <div class="mt-32 pl-32 pr-32">
    <div class="line" />
  </div>

  <div
    class="ft-26 black mt-16 pl-32 pr-32"
    style="text-align: left;line-height: 22px"
  >
    {{ data_info.content }}
  </div>

  <div class="pl-32 pr-32 mt-36">
    <span
      v-for="name in data_info.topicList"
      :key="name"
      class="topic-label"
    ># {{ name }}</span>
  </div>

  <div class="pl-32 pr-32 mt-32 flex flex-bt gray-l ft-10">
    <span>{{ $filters.formatDate(data_info.createDateTime) }}</span>
    <span v-if="info && data_info">{{ data_info.cityName }}</span>
  </div>
  <div class="line mt-32" />

  <div class="zc-bottom flex flex-bt flex-v-t">
    <van-field
      v-model="value"
      disabled
      class="ml-32 ft-24 pt-16 sub-field"
      placeholder="快来发表你的评论吧～"
    />
    <div class="ml-32 flex flex-v-b ft-20 gray-l mr-32">
      <img
        class="size-48"
        src="/static/image/love.png"
      >
      <span>{{ data_info.likedQuantity }}</span>

      <img
        class="size-48 ml-32"
        src="/static/image/store.png"
      >
      <span>{{ data_info.collectedQuantity }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ShareMoment',
  props: ['info'],
  setup(props) {
    const data_info = ref(props.info)

    return {
      data_info
    }
  }
})
</script>

<style scoped>

.img-video {
  width: 750px;
  height: 750px;

}

.custom-indicator {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 4px 30px;
  font-size: 24px;
  background: rgba(0, 0, 0, 0.39);
  color: white;
  border-radius: 26px;
}

.mine-img {
  height: 750px;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.sub-field {
  padding:10px 20px;
  background-color: #f2f2f2;flex: 1;border-radius: 28px;
  height:60px;
  line-height:40px;
}

</style>
